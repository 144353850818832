import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[appEmailValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: EmailValidatorDirective, multi: true}]
})

export class EmailValidatorDirective implements Validator {

  @Input('appEmailValidator') inputValue: any;

  validate(control: AbstractControl): {[key: string]: any} {
    if (this.isEnabled() && control.value) {
      if (!this.isFormatValid(control.value)) {
        return {'appFormat': true};
      }
    }
    return null;
  }

  isEnabled(): boolean {
    return this.inputValue !== undefined && this.inputValue !== false;
  }

  isFormatValid(value: string): boolean {
    return !!value.match(/^[A-Za-z0-9.!#$%&’+/=?^_`{|}~-]+@[A-Za-z0-9-]+\.[A-Za-z0-9-]+$/);
  }
}
