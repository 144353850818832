import { RedactPartPipe } from '../pipes/redact-part';import * as moment from 'moment';import { ProductFamilyPicklistValues } from './product-model';
import {AddressModel} from "./address-model";

export class PersonModel {
   
    get IsChexSystem(): string {
        return this._IsChexSystem;
    }

    set IsChexSystem(value: string) {
        this._IsChexSystem = value;
    }

    static readonly EmploymentStatusOptions = ['Employed','Unemployed',
        'Retired',
        'Self Employed',
    ];

    static readonly ResidentialStatusOptions = [
        'Rent',
        'Own',
    ];

    private _FirstName = '';
    private _MiddleName = '';
    private _LastName = '';
    private _Suffix = '';
    private _Email = '';
    private _MobilePhone = '';
    private _LandPhone = '';
    private _Ssn = '';
    private _Birthdate = '';
    private _LegalAuthorityDate = '';
    private _TeamEmail = '';

    private _Employer = '';
    private _Income = '';
    private _ValueOfAssets = '';
    private _ResidentialStatus = '';
    private _ResidentialAmount = '';
    private _EmploymentStatus = '';
    private _TotalCreditCardAccounts = '';
    private _ProductName = '';
    private _CurrentAddress = new AddressModel();
    private _PreviousAddress = new AddressModel();
    private _IsCreditBlock = false;
    private _IsCreditRepair = false;
    private _IsCreditDevelopment = false;
    private _IsCreditReport = false;
    private _IsCyberSweep = false;
    private _IsChexSystem:string;

    private _contactId:string;

    constructor() {
    }

    static fromJson(json: any): PersonModel {
        let person = new PersonModel();
        person.setFromJson(json);
        return person;
    }

    static setProductStageFlagsOnPerson(person: PersonModel, familyName: string) {
        switch (familyName) {
            case ProductFamilyPicklistValues.CreditBlock: // fallthrough switch for credit block products
            case ProductFamilyPicklistValues.CreditBlockUpsell:
                person.IsCreditBlock = true;
                break;
            case ProductFamilyPicklistValues.CreditRepair: // fallthrough switch for credit repair products
            case ProductFamilyPicklistValues.CreditRepairMonthly:
                person.IsCreditRepair = true;
                break;
            case ProductFamilyPicklistValues.CreditDevelopment:
                person.IsCreditDevelopment = true;
                break;
            case ProductFamilyPicklistValues.CreditDevWithPremiumCreditRepair:
                person.IsCreditDevelopment = true;
                person.IsCreditRepair = true;
                break;
            case ProductFamilyPicklistValues.CreditReport:
                person.IsCreditReport = true;
                break;
            case ProductFamilyPicklistValues.CyberSweep:
                person.IsCyberSweep = true;
                break
            case ProductFamilyPicklistValues.ChexSystem:
                person.IsChexSystem = "true";
                break;
            default:
                throw new Error(`Family name ${familyName} is not recognized.`);
        }
        return person;
    }

    setFromJson(json: any) {
        if (json.FirstName) {
            this.FirstName = json.FirstName;
        }
        if (json.MiddleName) {
            this.MiddleName = json.MiddleName;
        }
        if (json.LastName !== null) {
            this.LastName = json.LastName;
        }
        if (json.Suffix !== null) {
            this.Suffix = json.Suffix;
        }
        if (json.Email !== null) {
            this.Email = json.Email;
        }
        if (json.LastName !== null) {
            this.LastName = json.LastName;
        }
        if (json.MobilePhone !== null) {
            this.MobilePhone = json.MobilePhone;
        }
        if (json.LandPhone !== null) {
            this.LandPhone = json.LandPhone;
        }
        if (json.Ssn !== null) {
            this.Ssn = json.Ssn;
        }
        if (json.Birthdate !== null) {
            this.Birthdate = json.Birthdate;
        }
        if (json.TeamEmail !== null) {
            this.TeamEmail = json.TeamEmail;
        }
        if (json.LegalAuthorityDate !== null) {
            this.LegalAuthorityDate = json.LegalAuthorityDate;
        }

        if (json.TotalCreditCardAccounts !== null) {
            this.TotalCreditCardAccounts = json.TotalCreditCardAccounts;
        }

        if (json.ProductName !== null) {
            this.ProductName = json.ProductName;
        }

        if (json.IsCreditBlock !== null) {
            this.IsCreditBlock = json.IsCreditBlock;
        }

        if (json.IsCreditRepair !== null) {
            this.IsCreditRepair = json.IsCreditRepair;
        }

        if (json.IsCreditDevelopment !== null) {
            this.IsCreditDevelopment = json.IsCreditDevelopment;
        }

        if (json.IsCreditReport !== null) {
            this.IsCreditReport = json.IsCreditReport;
        }

        if (json.IsCyberSweep !== null) {
            this.IsCyberSweep = json.IsCyberSweep;
        }
        if(json.CurrentAddress !== null){
            this.CurrentAddress = json.CurrentAddress;
        }
        if(json.PreviousAddress !== null){
            this.PreviousAddress = json.PreviousAddress;
        }
        if(json.ContactId !== null){
            this.ContactId = json.ContactId;
        }
    }

    get TotalCreditCardAccounts(): string {
        return this._TotalCreditCardAccounts;
    }

    set TotalCreditCardAccounts(value: string) {
        this._TotalCreditCardAccounts = value;
    }

    get TeamEmail(): string {
        return this._TeamEmail;
    }

    set TeamEmail(value: string) {
        this._TeamEmail = value;
    }

    get FirstName(): string {
        return this._FirstName;
    }

    set FirstName(FirstName: string) {
        this._FirstName = FirstName;
    }

    get MiddleName(): string {
        return this._MiddleName;
    }

    set MiddleName(MiddleName: string) {
        this._MiddleName = MiddleName;
    }

    get LastName(): string {
        return this._LastName;
    }

    set LastName(LastName: string) {
        this._LastName = LastName;
    }

    get Suffix(): string {
        return this._Suffix;
    }

    set Suffix(Suffix: string) {
        this._Suffix = Suffix;
    }

    get Email(): string {
        return this._Email;
    }

    set Email(Email: string) {
        this._Email = Email;
    }

    get MobilePhone(): string {
        return this._MobilePhone;
    }

    set MobilePhone(MobilePhone: string) {
        this._MobilePhone = MobilePhone;
    }

    get LandPhone(): string {
        return this._LandPhone;
    }

    set LandPhone(LandPhone: string) {
        this._LandPhone = LandPhone;
    }

    get Ssn(): string {
        return this._Ssn;
    }

    set Ssn(Ssn: string) {
        if(this._Ssn !== '' && this._Ssn !== undefined && Ssn !== '000-00-0000'){
            this._Ssn = Ssn;
        }else if(this._Ssn === '' || this._Ssn === undefined && Ssn !== '000-00-0000'){
            this._Ssn = Ssn;
        }
        else if((this._Ssn === '' || this._Ssn === undefined) && Ssn === '000-00-0000'){
            this._Ssn = Ssn;
        }else{
            //do nothing
        }
    }

    get Birthdate(): string {
        return this._Birthdate;
    }

    set Birthdate(Birthdate: string) {
        this._Birthdate = Birthdate;
    }

    get LegalAuthorityDate(): string {
        return this._LegalAuthorityDate;
    }

    set LegalAuthorityDate(LegalAuthorityDate: string) {
        this._LegalAuthorityDate = LegalAuthorityDate;
    }

    get Employer(): string {
        return this._Employer;
    }

    set Employer(value: string) {
        this._Employer = value;
    }

    get Income(): string {
        return this._Income;
    }

    set Income(value: string) {
        this._Income = value;
    }

    get ValueOfAssets(): string {
        return this._ValueOfAssets;
    }

    set ValueOfAssets(value: string) {
        this._ValueOfAssets = value;
    }

    get ResidentialStatus(): string {
        return this._ResidentialStatus;
    }

    set ResidentialStatus(value: string) {
        this._ResidentialStatus = value;
    }

    get ResidentialAmount(): string {
        return this._ResidentialAmount;
    }

    set ResidentialAmount(value: string) {
        this._ResidentialAmount = value;
    }

    get EmploymentStatus(): string {
        return this._EmploymentStatus;
    }

    set EmploymentStatus(value: string) {
        this._EmploymentStatus = value;
    }

    get ProductName(): string {
        return this._ProductName;
    }

    set ProductName(value: string) {
        this._ProductName = value;
    }

    get IsCreditBlock() {
        return this._IsCreditBlock;
    }

    set IsCreditBlock(value) {
        this._IsCreditBlock = value;
    }

    get IsCreditRepair() {
        return this._IsCreditRepair;
    }

    set IsCreditRepair(value) {
        this._IsCreditRepair = value;
    }

    get IsCreditDevelopment() {
        return this._IsCreditDevelopment;
    }

    set IsCreditDevelopment(value) {
        this._IsCreditDevelopment = value;
    }

    get IsCreditReport(): boolean {
        return this._IsCreditReport;
    }

    set IsCreditReport(value: boolean) {
        this._IsCreditReport = value;
    }

    get IsCyberSweep(): boolean {
        return this._IsCyberSweep;
    }

    set IsCyberSweep(value: boolean) {
        this._IsCyberSweep = value;
    }

    get CurrentAddress():AddressModel {
        return this._CurrentAddress;
    }
    set CurrentAddress(value:AddressModel){
        this._CurrentAddress = value;
    }

    get PreviousAddress():AddressModel{
        return this._PreviousAddress;
    }
    set PreviousAddress(value: AddressModel){
        this._PreviousAddress = value;
    }

    get ContactId():string{
        return this._contactId;
    }
    set ContactId(value:string){
        this._contactId = value;
    }

    deepCopy(): PersonModel {
        const person = new PersonModel();
        person.FirstName = this.FirstName;
        person.MiddleName = this.MiddleName;
        person.LastName = this.LastName;
        person.Suffix = this.Suffix;
        person.Email = this.Email;
        person.MobilePhone = this.MobilePhone;
        person.LandPhone = this.LandPhone;
        person.Ssn = this.Ssn;
        person.Birthdate = this.Birthdate;
        person.LegalAuthorityDate = this.LegalAuthorityDate;
        person.TeamEmail = this.TeamEmail;
        person.Employer = this.Employer;
        person.Income = this.Income;
        person.ValueOfAssets = this.ValueOfAssets;
        person.ResidentialStatus = this.ResidentialStatus;
        person.ResidentialAmount = this.ResidentialAmount;
        person.EmploymentStatus = this.EmploymentStatus;
        person.TotalCreditCardAccounts = this.TotalCreditCardAccounts;
        person.ProductName = this.ProductName;
        person.IsCreditBlock = this.IsCreditBlock;
        person.IsCreditRepair = this.IsCreditRepair;
        person.IsCreditDevelopment = this.IsCreditDevelopment;
        person.IsCreditReport = this.IsCreditReport;
        person.IsCyberSweep = this.IsCyberSweep;
        person.IsChexSystem = this.IsChexSystem;
        person.CurrentAddress = this.CurrentAddress.deepCopy();
        person.PreviousAddress = this.PreviousAddress.deepCopy();
        person.ContactId = this._contactId;

        return person;
    }

    getFullName(): string {
        let names = [];
        if (this.FirstName) {
            names.push(this.FirstName);
        }
        if (this.MiddleName) {
            names.push(this.MiddleName);
        }
        if (this.LastName) {
            names.push(this.LastName);
        }
        if (this.Suffix) {
            names.push(this.Suffix);
        }
        return names.join(' ');
    }

    isUnder18(): boolean {
        if (!this._Birthdate) {

            return null;
        }
        let todayDate  = moment();
        let birthdate = moment(this._Birthdate);
        let years = todayDate.year() - birthdate.year();


        if (years == 18) {
            if (todayDate.month() == birthdate.month()) {
                return todayDate.date() < birthdate.date();
            } else {
                return todayDate.month() < birthdate.month();
            }
        } else {
            return years < 18;
        }
    }

    // currency validator formats to $0.00 format, with optional commas for thousands.
    // this only works with trailing zeroes!
    // we will remove the dollar sign and comma to make it a numeric value for the JSON.
    // should the currency validator change, this string dollar amount to number conversion will need to change also.
    toOpenJson(): any {
        let json = {
            'FirstName': this.FirstName,
            'MiddleName': this.MiddleName,
            'LastName': this.LastName,
            'Suffix': this.Suffix,
            'TeamEmail': this.TeamEmail,
            'Email': this.Email,
            'MobilePhone': this.MobilePhone,
            'LandPhone': this.LandPhone,
            'Ssn': this.Ssn,
            'Birthdate': this.Birthdate,
            'IsUnder18': this.isUnder18(),
            'TotalCreditCardAccounts': this.TotalCreditCardAccounts,
            'ProductName': this.ProductName,
            'Employer': this.Employer,
            'EmploymentStatus': this.EmploymentStatus,
            'Income': this.currencyToNumber(this.Income),
            'ResidentialAmount': this.currencyToNumber(this.ResidentialAmount),
            'IsCreditBlock': this.IsCreditBlock,
            'IsCreditRepair': this.IsCreditRepair,
            'IsCreditDevelopment': this.IsCreditDevelopment,
            'IsCreditReport': this.IsCreditReport,
            'IsCyberSweep': this.IsCyberSweep,
            'IsChexSystem': this.IsChexSystem,
            'CurrentAddress': this.CurrentAddress.toOpenJson(),
            'PreviousAddress': this.PreviousAddress.toOpenJson(),
            'ContactId': this._contactId
        };

        return json;
    }

    toRedactedJson(): any {
        let redactPart = new RedactPartPipe();
        let json = {
            'FirstName': redactPart.transform(this.FirstName, 1),
            'MiddleName': redactPart.transform(this.MiddleName, 1),
            'LastName': redactPart.transform(this.LastName, 1),
            'Suffix': this.Suffix,
            'TeamEmail': this.TeamEmail,
            'Email': redactPart.transform(this.Email, 1),
            'MobilePhone': redactPart.transform(this.MobilePhone, 5),
            'LandPhone': redactPart.transform(this.LandPhone, 5),
            'Ssn': redactPart.transform(this.Ssn, -2),
            'Birthdate': redactPart.transform(this.Birthdate, -4),
            'IsUnder18': this.isUnder18(),
            'TotalCreditCardAccounts': this.TotalCreditCardAccounts,
            'ProductName': this.ProductName,
            'Employer': this.Employer,
            'EmploymentStatus': this.EmploymentStatus,
            'Income': this.currencyToNumber(this.Income),
            'ResidentialAmount': this.currencyToNumber(this.ResidentialAmount),
            'IsCreditBlock': this.IsCreditBlock,
            'IsCreditRepair': this.IsCreditRepair,
            'IsCreditDevelopment': this.IsCreditDevelopment,
            'IsCreditReport': this.IsCreditReport,
            'IsCyberSweep': this.IsCyberSweep,
            'IsChexSystem': this.IsChexSystem,
            'CurrentAddress': this.CurrentAddress.toRedactedJson(),
            'PreviousAddress': this.PreviousAddress.toRedactedJson(),
            'ContactId':this.ContactId
        };
        return json;
    }

    currencyToNumber(currencyString) {
        return Number(currencyString.replace(/[^0-9.-]+/g, ''));
    }
}
