import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
    ViewContainerRef
} from '@angular/core';
import {ModalDialogService, SimpleModalComponent} from 'ngx-modal-dialog';
import {FormFieldComponent} from '../form-field/form-field';
import {ApiGatewayService} from '../../services/api-gateway';
import {OrderDataService} from '../../services/order-data';
import {EnvironmentService} from '../../services/environment.service';
import {AddressComponent} from "../address/address.component";
import {AddressModel} from "../../models/address-model";

@Component({
    selector: 'app-wz-step-address',
    templateUrl: './wz-step-address.html',
    styleUrls: ['./wz-step-address.scss']
})

export class WzStepAddressComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChildren(FormFieldComponent) formFields: QueryList<FormFieldComponent>;
    @ViewChildren(AddressComponent) AddressFields: QueryList<AddressComponent>;
    @ViewChild('firstName') firstName: FormFieldComponent;
    @ViewChild('lastName') lastName: FormFieldComponent;
    @Input()
    @Output() onSendOrder: EventEmitter<any> = new EventEmitter();
    @Output() clear = new EventEmitter();
    partnerKey = null;

    constructor(
        public modal: ModalDialogService,
        public viewRef: ViewContainerRef,
        public apiGateway: ApiGatewayService,
        public orderData: OrderDataService,
        public envSvc: EnvironmentService
    ) {
    }

    private _billingAddressData = new AddressModel();

    get BillingAddressData(): AddressModel {
        return this._billingAddressData;
    }
    set BillingAddressData(value:AddressModel){
        this._billingAddressData = value;
        this.orderData.order.CreditCard.BillingAddress = value;
    }
    ngOnInit() {
    }

    ngOnChanges() {
    }

    ngAfterViewInit() {
    }

    onClear() {
        this.clear.emit(this.formFields);
    }

    onStepEnter() {
    }

    private showErrorModal(title: string, text: string) {
        this.modal.openDialog(this.viewRef, {
            childComponent: SimpleModalComponent,
            settings: {
                contentClass: 'modal-content error'
            },
            title: title,
            data: {
                text: text
            },
            actionButtons: [{
                text: 'OK',
                buttonClass: 'btn btn-light'
            }]
        });
    }
}
