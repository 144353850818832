import { Component, EventEmitter, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormFieldComponent } from '../../components/form-field/form-field'
import { PhoneValidatorDirective } from '../../directives/phone-validator';
import { SsnValidatorDirective } from '../../directives/ssn-validator';
import { DateValidatorDirective } from '../../directives/date-validator';
import { ProductDataService } from '../../services/product-data';
import { OrderDataService } from '../../services/order-data';
import { ProductModel } from '../../models/product-model';
import { PersonModel } from '../../models/person-model';
import { ProductFamilyListModel, ProductFamilyType } from '../../models/product-family-list-model';
import { EnvironmentService } from '../../services/environment.service';
import { ProductFamilyModel } from '../../models/product-family-model';
import { from } from 'rxjs/observable/from';
import { Observable } from 'rxjs';
import { PersonAccountDataService } from '../../services/person-account';

@Component({
    selector: 'app-wz-step-family-info',
    templateUrl: './wz-step-family-info.html',
    styleUrls: ['./wz-step-family-info.scss']
})

export class WzStepFamilyInfoComponent implements OnInit {

    readonly phonePlaceholder = PhoneValidatorDirective.readableFormat;
    readonly ssnPlaceholder = SsnValidatorDirective.readableFormat;
    readonly datePlaceholder = DateValidatorDirective.readableFormat;
    readonly familyType = ProductFamilyType;

    @ViewChildren(FormFieldComponent) formFields: QueryList<FormFieldComponent>;
    @Output() clear = new EventEmitter();
    familyList$ = new Observable<ProductFamilyListModel>();
    primaryProduct: ProductModel;
    spouseProduct: ProductModel;
    childProduct: ProductModel;

    primaryProduct$: Observable<ProductModel> = new Observable<ProductModel>();
    spouseProduct$: Observable<ProductModel> = new Observable<ProductModel>();
    childProduct$: Observable<ProductModel> = new Observable<ProductModel>();

    isFree = false;
    spouseMonthly = 0;
    spousePartnerMonthly = 0;

    spouseProductPrice = 0;

    partnerKey = null;

    constructor(
        public productData: ProductDataService,
        public orderData: OrderDataService,
        public envSvc: EnvironmentService,
        public personAcctSvc: PersonAccountDataService) {
    }

    ngOnInit() {
        this.familyList$ = from(this.productData.buildFamilyList(this.orderData.order.PartnerKey));
        this.getPrimaryProduct();
        this.getSpouseProduct();
        this.getChildProduct();
        if(this.orderData.order.PartnerKey){
          this.partnerKey = this.orderData.order.PartnerKey;
        }
    }

    hasPartnerKey() {
        return this.orderData.order.PartnerKey ? true : false
    }

    addSpouse() {
        if (!this.orderData.order.LeadSpouse) {
            this.orderData.order.LeadSpouse = new PersonModel();
        }
        this.setCyberSweepSpouseDefaults();
    }

    removeSpouse() {
        this.orderData.order.LeadSpouse = null;
    }

    addChild() {
        var child = new PersonModel();
        this.setContactFromPrimary(child);
        this.orderData.order.addChild(child);
    }

    removeChild(i: number) {
        this.orderData.order.LeadChildren.splice(i, 1);
    }
    setCyberSweepSpouseDefaults(){
        if(this.isCyberSweep()){
            this.orderData.order.LeadSpouse.Ssn  = '000-00-0000';
        }
    }

    isCreditReport(){
        let creditreportResult = false;
        if (this.envSvc.getProductFromSubdomain() === 'creditreport') {
            creditreportResult = true;
        }
        return creditreportResult;
    }
    isCyberSweep() {
        let cyberSweepResult = false;
        if (this.envSvc.getProductFromSubdomain() === 'cybersweep') {
            cyberSweepResult = true;
        }
        return cyberSweepResult;
    }

    getSpouseProduct() {
        this.familyList$.subscribe((familyList) => {
            const familyName = this.envSvc.getProductFamilyFromSubdomain();
            const products: ProductFamilyModel[] = this.productData.findProductFamilyList(familyName);
            this.spouseProduct = this.productData.findProduct(products, ProductFamilyType.Spouse);
            this.isFree = familyList.HasFreeCreditReport === "YES";
            if (this.spouseProduct.PartnerPrice) {
                this.spouseProductPrice = this.spouseProduct.PartnerPrice;
            } else {
                this.spouseProductPrice = this.productData.getProductPricing(familyName, this.personAcctSvc.personAccount, ProductFamilyType.Spouse, this.hasPartnerKey());
            }
            this.spouseProduct$ = new Observable<ProductModel>((o => {
                o.next(this.spouseProduct);
            }));

            if(this.partnerKey != null){
              if (this.productData.shouldFetchMonthlyPrice()) {
                  this.getSpouseMonthlyPartnerPrice();
              }
            } else {
              if (this.productData.shouldFetchMonthlyPrice()) {
                  this.getSpouseMonthlyPrice();
              }
            }
        });
    }

    getSpouseMonthlyPrice() {
        this.familyList$.subscribe((familyList) => {

            const products: ProductFamilyModel[] = this.productData.findProductFamilyList('Credit Repair Monthly');

            this.spouseMonthly = this.productData.findMonthlyPrice(products, ProductFamilyType.Spouse, this.partnerKey);

        });
    }

    getSpouseMonthlyPartnerPrice() {
        this.familyList$.subscribe((familyList) => {

            const products: ProductFamilyModel[] = this.productData.findProductFamilyList('Credit Repair Monthly');

            this.spouseMonthly = this.productData.findMonthlyPrice(products, ProductFamilyType.Spouse, this.partnerKey);

        });
    }

    getPrimaryProduct() {
        this.familyList$.subscribe((familyList) => {
            const products: ProductFamilyModel[] = this.productData.findProductFamilyList(this.envSvc.getProductFamilyFromSubdomain());
            this.primaryProduct = this.productData.findProduct(products, ProductFamilyType.Self);
            this.primaryProduct$ = new Observable<ProductModel>((o => {
                o.next(this.primaryProduct);
            }));
        });
    }

    getChildProduct() {
        this.familyList$.subscribe((familyList) => {
            const products: ProductFamilyModel[] = this.productData.findProductFamilyList(this.envSvc.getProductFamilyFromSubdomain());
            this.childProduct = this.productData.findProduct(products, ProductFamilyType.Child);
            this.childProduct$ = new Observable<ProductModel>((o => {
                o.next(this.childProduct);
            }));
        });
    }

    getOrdinalHtml(cardinalNumber: number): string {
        cardinalNumber = Math.round(cardinalNumber);
        let ordinalSuffix = 'th';
        let tensDigit = Math.floor(cardinalNumber / 10) % 10;
        if (tensDigit != 1) {
            let onesDigit = cardinalNumber % 10;
            switch (onesDigit) {
                case 1:
                    ordinalSuffix = 'st';
                    break;
                case 2:
                    ordinalSuffix = 'nd';
                    break;
                case 3:
                    ordinalSuffix = 'rd';
                    break;
            }
        }
        return cardinalNumber.toString() + '<sup>' + ordinalSuffix + '</sup>';
    }

    setContactFromPrimary(person: PersonModel) {
        person.Email = this.orderData.order.LeadSelf.Email;
        person.MobilePhone = this.orderData.order.LeadSelf.MobilePhone;
    }

    onChangeLegalAuthority(person: PersonModel) {
        if (person.LegalAuthorityDate) {
            person.LegalAuthorityDate = (new Date()).toString() // TODO: format?;
        } else {
            person.LegalAuthorityDate = '';
        }
    }

    onClear() {
        this.clear.emit(this.formFields);
    }

    onStepEnter() {
    }
}
