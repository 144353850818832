import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[appSsnValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: SsnValidatorDirective, multi: true}]
})

export class SsnValidatorDirective implements Validator {

  static readonly readableFormat = 'nnn-nn-nnnn';

  @Input('appSsnValidator') fallbackValue: any;

  validate(control: AbstractControl): {[key: string]: any} {
    if (control.value) {
      let reducedValue = this.reduceValue(control.value);
      let reducedFallbackValue = this.reduceValue(this.fallbackValue ? this.fallbackValue : '');
      if (!this.isFormatValid(reducedValue)) {
        if (!this.isFormatValid(reducedFallbackValue)) {
          return {'appFormat': SsnValidatorDirective.readableFormat};
        }
        if (!this.isValueValid(reducedFallbackValue)) {
          return {'appValue': true};
        }
        return null;
      }
      if (!this.isValueValid(reducedValue)) {
        return {'appValue': true};
      }
      let formattedValue = this.formatValue(reducedValue);
      if (control.value != formattedValue) {
        control.setValue(formattedValue);
      }
    }
    return null;
  }

  isEnabled(): boolean {
    return this.fallbackValue !== undefined && this.fallbackValue !== false;
  }

  reduceValue(value: string): string {
    return value.replace(/[ -]/g, '');
  }

  isFormatValid(value: string): boolean {
    return !!value.match(/^[0-9]{9}$/);
  }

  isValueValid(value: string): boolean {
    return !value.match(/^000/) && !value.match(/^...00/) && !value.match(/0000$/);
  }

  formatValue(value: string): string {
    return value.replace(/^(.{3})(.{2})/, '$1-$2-');
  }
}
