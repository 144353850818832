import { Component, EventEmitter, OnInit, Output, QueryList, ViewChildren, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalDialogService } from 'ngx-modal-dialog';
import { FormFieldComponent } from '../../components/form-field/form-field';
import { ProductDataService } from '../../services/product-data';
import { OrderDataService } from '../../services/order-data';
import { TranslateService } from '@ngx-translate/core';
import { EnvironmentService } from '../../services/environment.service';
import { PersonAccountDataService } from '../../services/person-account';
import { ProductFamilyModel } from '../../models/product-family-model';
import { from } from 'rxjs/observable/from';
import { Observable } from 'rxjs';
import { ProductFamilyListModel, ProductFamilyType } from '../../models/product-family-list-model';
import { QueryParamObject } from '../../pages/wizard-page/wizard-page';

@Component({
    selector: 'app-wz-step-start',
    templateUrl: './wz-step-start.html',
    styleUrls: ['./wz-step-start.scss']
})

export class WzStepStartComponent implements OnInit {
    product: { value: string };

    hideNavBar = true;
    @ViewChildren(FormFieldComponent) formFields: QueryList<FormFieldComponent>;
    @Output() clear = new EventEmitter();
    showHideSmartCreditBlock = true;
    standardPrice: number;
    partnerPrice: number;
    monthlyPrice: number;
    partnerMonthlyPrice: number;
    standardPrice$: Observable<number> = new Observable<number>();
    partnerPrice$: Observable<number> = new Observable<number>();
    monthlyPrice$: Observable<number> = new Observable<number>();
    partnerMonthlyPrice$: Observable<number> = new Observable<number>();
    familyList$ = new Observable<ProductFamilyListModel>();
    personAccount$ = new Observable<any>();
    hasPartnerKey = null;
    hasFreeCreditReport = false;

    constructor(
        public route: ActivatedRoute,
        public modal: ModalDialogService,
        public viewRef: ViewContainerRef,
        public productData: ProductDataService,
        public orderData: OrderDataService,
        private translate: TranslateService,
        protected envSvc: EnvironmentService,
        public personAcctSvc: PersonAccountDataService) {
    }

    ngOnInit() {
        let personAccountId;
        this.showHideSmartCredit();
        this.route.queryParamMap.subscribe(paramsResult => {
        this.route.params.subscribe((routeParams) => {
            if (routeParams.partnerKey) {
                this.orderData.order.PartnerKey = routeParams.partnerKey;
                this.hasPartnerKey = routeParams.partnerKey;
            }

            const queryParams = { ...paramsResult.keys, ...paramsResult } as QueryParamObject;
            const params = queryParams.params;
            if (params.pid) {
                personAccountId = params.pid;
                this.personAccount$ = from(this.personAcctSvc.getPersonAccount(personAccountId));
            } else {
                this.personAccount$ = from(Promise.resolve());
            }

            this.familyList$ = from(this.productData.buildFamilyList(this.orderData.order.PartnerKey));
            this.getStandardPrice();
            this.getPartnerPrice();

            if (this.productData.shouldFetchMonthlyPrice()) {

                this.getMonthlyPrice();
            }

            if (this.productData.shouldFetchMonthlyPrice()) {

                this.getMonthlyPartnerPrice();
            }

        });
        });

        // Initialize Product name from translate files for translate string parameters
        // like 'FOO' | translate:product
        this.translate.get('PRODUCT_TITLE').subscribe((res: string) => {
            this.product = { value: res };
        });
    }

    showHideSmartCredit() {
        this.showHideSmartCreditBlock = false;
    }

    shouldShowDiscount(){
        var result = false;
       if(this.standardPrice > this.partnerPrice && this.partnerPrice > 0){
            result = true;
        }
        return result;
    }

    getStandardPrice() {
        this.familyList$.subscribe((familyList) => {
            const familyName = this.envSvc.getProductFamilyFromSubdomain();
            this.personAccount$.subscribe( () => {
                this.standardPrice = this.productData.getProductPricing(familyName, this.personAcctSvc.personAccount, ProductFamilyType.Self, false);
            });
        });
    }

    hasStandardPrice(): boolean {
        return this.standardPrice !== null;
    }

    getPartnerPrice() {
        this.familyList$.subscribe((familyList) => {
            const products: ProductFamilyModel[] = this.productData.findProductFamilyList(this.envSvc.getProductFamilyFromSubdomain());
            const family: ProductFamilyModel = this.productData.findFamilyTypeFamily(products, ProductFamilyType.Self);
            this.personAccount$.subscribe( () => {
                if (family.Self && family.Self.PartnerPrice) {
                    this.partnerPrice = this.productData.getProductPricing(family.FamilyName, this.personAcctSvc.personAccount, ProductFamilyType.Self, this.hasPartnerKey);
                }
                this.FreeCreditReport();
            });
        });

    }

    private FreeCreditReport() {
        if (this.envSvc.getProductFamilyFromSubdomain() === 'Credit Report') {
            if (this.productData.familyList.HasFreeCreditReport === 'YES') {
                this.partnerPrice = 0;
                this.hasFreeCreditReport = true;
            }
        }
    }

    getMonthlyPrice() {
        this.familyList$.subscribe((familyList) => {

            const products: ProductFamilyModel[] = this.productData.findProductFamilyList('Credit Repair Monthly');

            this.monthlyPrice = this.productData.findMonthlyPrice(products, ProductFamilyType.Self, this.orderData.order.PartnerKey);
        });
    }

    getMonthlyPartnerPrice() {
        this.familyList$.subscribe((familyList) => {

            const products: ProductFamilyModel[] = this.productData.findProductFamilyList('Credit Repair Monthly');

            this.partnerMonthlyPrice = this.productData.findMonthlyPrice(products, ProductFamilyType.Self, this.orderData.order.PartnerKey);
        });
    }

    hasPartnerPrice(): boolean {
        return this.partnerPrice !== null;
    }

    onClear() {
        this.clear.emit(this.formFields);
    }

    showCreditReportPanel() {
        return this.envSvc.getProductFromSubdomain() === 'creditreport';
    }

    showAddlPriceText() {
        const productFamily: string = this.envSvc.getProductFromSubdomain();

        switch (productFamily) {
            case 'creditrepairwithdevelopment':
            case 'creditrepair':
                return true;
            default:
                return false;
        }
    }

    getAddlPriceText() {
        const productFamily: string = this.envSvc.getProductFromSubdomain();

        switch (productFamily) {
            case 'creditrepairwithdevelopment':
            case 'creditrepair':
                return ` audit and consultation fee plus ongoing $${this.monthlyPrice}/month`;
            default:
                return '';
        }

    }

    getAddlPriceTextPartner() {
        const productFamily: string = this.envSvc.getProductFromSubdomain();

        switch (productFamily) {
            case 'creditrepairwithdevelopment':
            case 'creditrepair':
                return ` audit and consultation fee plus ongoing $${this.partnerMonthlyPrice}/month`;
            default:
                return '';
        }

    }
}
