import { ProductModel } from './product-model';
import { PriceHtmlPipe } from '../pipes/price-html';

export class OrderLineModel {
    
    protected _hideCurrencySymbol: boolean;

    constructor(Description: string, FamilyName: string, Price: number, hideCurrencySymbol = false) {
        this._Description = Description;
        this._FamilyName = FamilyName;
        this._Price = Price;
        this._hideCurrencySymbol = hideCurrencySymbol;
    }

    protected _Description: string;

    get Description(): string {
        return this._Description;
    }
    
    private _FamilyName: string;
    
    get FamilyName(): string {
        return this._FamilyName;
    }
    
    set FamilyName(value: string) {
        this._FamilyName = value;
    }
    
    protected _Price: number;
    
    get Price(): number {
        return this._Price;
    }
    
    set Price(Price: number) {
        this._Price = Price;
    }
    
    get PriceHtml(): string {
        return (new PriceHtmlPipe()).transform(this.Price, '', this._hideCurrencySymbol);
    }
    
    deepCopy(): OrderLineModel {
        const orderLine = new OrderLineModel(this.Description, this.FamilyName, this.Price, this._hideCurrencySymbol);
        return orderLine;
    }
}
