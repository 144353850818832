import { Component, OnInit } from '@angular/core';
import { EnvironmentService } from '../../services/environment.service';

@Component({
    selector: 'app-terms-conditions',
    templateUrl: './terms-conditions.component.html',
    styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {
    
    constructor(private envSvc: EnvironmentService) {
    }
    
    ngOnInit() {
    }
    
    isCreditBlock(): boolean {
        return this.envSvc.getProductFamilyFromSubdomain() === 'Credit Block';
    }
    
}
