import {Component, EventEmitter, OnInit, Output, QueryList, ViewChildren} from '@angular/core';

import {OrderDataService} from "../../services/order-data";
import {PersonModel} from "../../models/person-model";
import {FormFieldComponent} from "../form-field/form-field";

@Component({
  selector: 'app-wz-step-employment-info',
  templateUrl: './wz-step-employment-info.component.html',
  styleUrls: ['./wz-step-employment-info.component.scss']
})
export class WzStepEmploymentInfoComponent implements OnInit {

  @ViewChildren(FormFieldComponent) formFields: QueryList<FormFieldComponent>;
  @Output() clear = new EventEmitter();

  readonly employeeStatusOptions = PersonModel.EmploymentStatusOptions;
  readonly residentialStatusOptions = PersonModel.ResidentialStatusOptions;

  constructor(
      public orderData: OrderDataService
  ) { }



  ngOnInit() {
  }

  onClear() {
    this.clear.emit(this.formFields);
  }

}
