import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-wz-nav-btns-row',
  templateUrl: './wz-nav-btns-row.html',
  styleUrls: ['./wz-nav-btns-row.scss']
})

export class WzNavBtnsRowComponent {

  @Input() isPenultimateStep = false;
  @Output() clear = new EventEmitter();
  @Output() onSendOrder: EventEmitter<any> = new EventEmitter();

  constructor() {
  }
  
  
  sendOrder() {
      this.onSendOrder.emit();
  }
  
  onClickClear() {
    this.clear.emit();
  }
}
