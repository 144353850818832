import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'priceHtml'
})

export class PriceHtmlPipe implements PipeTransform {

  transform(value: number, frequency = '', hideSymbol = false): string {
    if (value === null) {
      return '';
    } else if (value === 0) {
      return 'FREE';
    } else {
      let priceHtml = '';
      if (value < 0) {
        priceHtml += '&ndash;';
      }
      if (!hideSymbol) {
        priceHtml += '$';
      }
      priceHtml += new DecimalPipe('en-IN').transform(Math.abs(value), '1.2-2');
      if (frequency) {
        priceHtml += ' <span class="small">' + frequency + '</span>';
      }
      return priceHtml;
    }
  }
}
