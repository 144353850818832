import { Injectable } from '@angular/core';
import { ApiGatewayService } from './api-gateway';
import { PersonModel } from '../models/person-model';
import { AsyncSubject } from 'rxjs';

@Injectable()
export class PersonAccountDataService {
    public personAccountAsyncSubject: AsyncSubject<PersonModel> = new AsyncSubject();
    public personAccount: PersonModel;

    constructor(
        private apiGateway: ApiGatewayService) {
    }

    getPersonAccount(personAccountId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.apiGateway.getPersonAccount(personAccountId).then((personAccount: PersonModel) => {
                const stringBirthdate = this.transformMsBirthdate(personAccount.Birthdate);
                personAccount.Birthdate = stringBirthdate;
                this.notify(personAccount);

                this.personAccount = personAccount;
                this.personAccountAsyncSubject.complete();
                resolve();
            }).catch((err) => {
                reject(err);
            });
        });
    }

    transformMsBirthdate(birthdate: any) {
        if (typeof birthdate === 'number') {
            try {
                const date = new Date(birthdate);
                return date.toLocaleDateString('en-US');
            } catch (e) {
                console.log(e);
            }
        }
        return birthdate;
    }

    notify(message: any) {
        this.personAccountAsyncSubject.next(message);
    }
}
