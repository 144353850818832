import { AddressModel } from './address-model';
import { RedactPartPipe } from '../pipes/redact-part';

export class CreditCardModel {

  private _Number = '';
  private _Expiration = '';
  private _CvcCode = '';
  private _BillingAddress = new AddressModel();
  private _CardType = '';
  private _FirstName = '';
  private _LastName ='';

  constructor() {
  }
    get LastName(): string {
        return this._LastName;
    }
    set LastName(type: string) {
        this._LastName = type;
    }
    get FirstName(): string {
        return this._FirstName;
    }
    set FirstName(type: string) {
        this._FirstName = type;
    }
    get CardType(): string {
        return this._CardType;
    }
    set CardType(type: string) {
        this._CardType = type;
    }

  get Number(): string {
    return this._Number;
  }
  set Number(Number: string) {
    this._Number = Number;
  }

  get Expiration(): string {
    return this._Expiration;
  }
  set Expiration(Expiration: string) {
    this._Expiration = Expiration;
  }

  get CvcCode(): string {
    return this._CvcCode;
  }
  set CvcCode(CvcCode: string) {
    this._CvcCode = CvcCode;
  }

  get BillingAddress(): AddressModel {
    return this._BillingAddress;
  }
  set BillingAddress(BillingAddress: AddressModel) {
    this._BillingAddress = BillingAddress;
  }

  deepCopy(): CreditCardModel {
    let creditCard = new CreditCardModel();
    creditCard.Number = this.Number;
    creditCard.Expiration = this.Expiration;
    creditCard.CvcCode = this.CvcCode;
    creditCard.CardType = this.CardType;
    creditCard.BillingAddress = this.BillingAddress.deepCopy();
    creditCard.LastName = this.LastName;
    creditCard.FirstName = this.FirstName;
    return creditCard;
  }

  toOpenJson(): any {
    let json = {
      'Number': this.Number,
      'Expiration': this.Expiration,
      'CvcCode': this.CvcCode,
        'CardType': this.CardType,
        'FirstName': this.FirstName,
        'LastName': this.LastName,
      'BillingAddress': this.BillingAddress.toOpenJson()
    };
    return json;
  }

  toRedactedJson(): any {
    let redactPart = new RedactPartPipe();
    let json = {
      'Number': redactPart.transform(this.Number, -4),
      'Expiration': redactPart.transform(this.Expiration, -4),
      'CvcCode': redactPart.transform(this.CvcCode, -1),
        'CardType': this.CardType,
        'FirstName': redactPart.transform(this.FirstName, 1),
        'LastName': redactPart.transform(this.LastName, 1),
      'BillingAddress': this.BillingAddress.toRedactedJson()
    };
    return json;
  }
}
