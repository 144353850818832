import { Component, OnInit, Input } from '@angular/core';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-invalid-message',
  templateUrl: './invalid-message.html',
  styleUrls: ['./invalid-message.scss']
})

export class InvalidMessageComponent implements OnInit {

  @Input() model: NgModel;
  @Input() requiredMessage = 'Required';
  @Input() invalidFormatMessage = 'Invalid Format';
  @Input() invalidValueMessage = 'Invalid Value';
  @Input() invalidCCMessage = 'We Accept: Visa / MasterCard / American Express / Discover Card only!';

  constructor() {
  }

  ngOnInit() {
  }
}
