import {ErrorHandler,  Injectable, Injector} from '@angular/core';
import {NotificationService} from "../services/notification.service";
import {ErrorMessage} from "../models/error-message";


@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler{
    id =  Date.now();
  constructor(private injector: Injector) { console.log("Write Out " + this.id);}


  handleError(error){
      const notificationService = this.injector.get(NotificationService);
        let  currentMessage = new ErrorMessage('There has been a network failure. Please resubmit or call us at 800-843-0677',
          'Network Error');
        notificationService.notify(currentMessage);
       console.log(error);
  }
}
