import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { OrderDataService } from '../../services/order-data';
import { PhoneValidatorDirective } from '../../directives/phone-validator';
import { SsnValidatorDirective } from '../../directives/ssn-validator';
import { DateValidatorDirective } from '../../directives/date-validator';
import { PersonModel } from '../../models/person-model';
import { PersonAccountDataService } from '../../services/person-account';
import { EnvironmentService } from '../../services/environment.service';
import { ProductDataService } from '../../services/product-data';
import { ProductModel } from '../../models/product-model';
import { FormFieldComponent } from '../form-field/form-field';
import { ApiGatewayService } from '../../services/api-gateway';
import { OrderLineModel } from '../../models/order-line-model';
import { OrderModel } from '../../models/order-model';
import { isNullOrUndefined } from 'util';
import { ProductFamilyListModel, ProductFamilyType } from '../../models/product-family-list-model';
import { ProductFamilyModel } from '../../models/product-family-model';
import { from } from 'rxjs/observable/from';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-wz-step-credit-block-addon',
    templateUrl: './wz-step-credit-block-addon.component.html',
    styleUrls: ['./wz-step-credit-block-addon.component.scss']
})
export class WzStepCreditBlockAddonComponent implements OnInit {

    readonly phonePlaceholder = PhoneValidatorDirective.readableFormat;
    readonly ssnPlaceholder = SsnValidatorDirective.readableFormat;
    readonly datePlaceholder = DateValidatorDirective.readableFormat;
    @ViewChildren(FormFieldComponent) formFields: QueryList<FormFieldComponent>;
    @ViewChild('ssnSelf') ssnSelfField: FormFieldComponent;
    @ViewChild('ssnSpouse') ssnSpouseField: FormFieldComponent;
    @Output() clear = new EventEmitter();
    acceptCreditBlockAddition: boolean;
    acceptCreditBlockSpouseAddition: boolean;
    acceptCreditBlockAdditionValue: string;
    acceptCreditBlockSpouseAdditionValue: string;
    allOrderLines = new Array<OrderLineModel>();
    discountOrderLine: OrderLineModel;
    totalOrderLine: OrderLineModel;
    childCompanyPhone = this.envSvc.config.companyPhone;
    childSpecialPrice = 0;
    public companyPhone = { value: this.envSvc.config.companyPhone };
    protected managedType = '';
    private personInformationDefault = 'Your Information';
    private personInformationAccountManaged = 'Client Information';
    selfPriceSpecial = 0;
    spousePriceSpecial = 0;
    selfPriceRegular = 0;
    spousePriceRegular = 0;
    familyList$ = new Observable<ProductFamilyListModel>();
    partnerKey = null;
    childPriceRegular = 0;

    constructor(protected envSvc: EnvironmentService,
                protected orderData: OrderDataService,
                protected productData: ProductDataService,
                protected apiGateway: ApiGatewayService,
                protected changeRef: ChangeDetectorRef) {
    }

    private _firstNameText: string;

    get firstNameText(): string {
        return this._firstNameText;
    }

    set firstNameText(value: string) {
        this._firstNameText = value;
        this.orderData.order.LeadSelf.FirstName = value;
    }

    private _middleNameText: string;

    get middleNameText(): string {
        return this._middleNameText;
    }

    set middleNameText(value: string) {
        this._middleNameText = value;
        this.orderData.order.LeadSelf.MiddleName = value;
    }

    private _lastNameText: string;

    get lastNameText(): string {
        return this._lastNameText;
    }

    set lastNameText(value: string) {
        this._lastNameText = value;
        this.orderData.order.LeadSelf.LastName = value;
    }

    private _suffixText: string;

    get suffixText(): string {
        return this._suffixText;
    }

    set suffixText(value: string) {
        this._suffixText = value;
        this.orderData.order.LeadSelf.Suffix = value;
    }

    private _teamEmailText: string;

    get teamEmailText(): string {
        return this._teamEmailText;
    }

    set teamEmailText(value: string) {
        this._teamEmailText = value;
        this.orderData.order.LeadSelf.TeamEmail = value;
    }

    private _emailText: string;

    get emailText(): string {
        return this._emailText;
    }

    set emailText(value: string) {
        this._emailText = value;
        this.orderData.order.LeadSelf.Email = value;
    }

    private _ssnText: string;

    get ssnText(): string {
        return this._ssnText;
    }

    set ssnText(value: string) {
        this._ssnText = value;
        this.orderData.order.LeadSelf.Ssn = value;
    }

    private _birthdateText: string;

    get birthdateText(): string {
        return this._birthdateText;
    }

    set birthdateText(value: string) {
        this._birthdateText = value;
        this.orderData.order.LeadSelf.Birthdate = value;
    }

    private _mobilePhoneText: string;

    get mobilePhoneText(): string {
        return this._mobilePhoneText;
    }

    set mobilePhoneText(value: string) {
        this._mobilePhoneText = value;
        this.orderData.order.LeadSelf.MobilePhone = value;
    }

    private _personInformation = this.personInformationDefault;

    get personInformation(): string {
        if (this.orderData.order.isAccountManaged()) {
            return this.personInformationAccountManaged;
        }
        return this._personInformation;
    }

    set personInformation(value: string) {
        this._personInformation = value;
    }

    ngOnInit() {
      this.familyList$ = from(this.productData.buildFamilyList(this.orderData.order.PartnerKey));
      this.getChildMonthlyPrice();
    }

    addChild() {
        const child = new PersonModel();
        this.setContactFromPrimary(child);
        this.orderData.order.addChild(child);
    }

    getSelfAcceptText(){
        let label = 'CREDIT_BLOCK_UPSELL_ACCEPT2_LABEL';
        if(this.hasStandardPrice() && this.hasPartnerPrice()){
            label = 'CREDIT_BLOCK_UPSELL_ACCEPT_LABEL';
        }
        return label;
    }

    getSpouseAcceptText(){
        let label = 'CREDIT_BLOCK_UPSELL_SPOUSE_ACCEPT2_LABEL';
        if(this.hasStandardPrice() && this.hasPartnerPrice()){
            label = 'CREDIT_BLOCK_UPSELL_SPOUSE_ACCEPT_LABEL';
        }
        return label;
    }
    

    getChildMonthlyPrice() {

      console.log('getChildMonthlyPrice');
        this.familyList$.subscribe((familyList) => {

            const products: ProductFamilyModel[] = this.productData.findProductFamilyList('Credit Repair Monthly');


            this.childSpecialPrice = this.productData.findMonthlyPrice(products, ProductFamilyType.Child, this.partnerKey);

            console.log('Child Price: ' + this.childSpecialPrice);

        });
    }

    setContactFromPrimary(person: PersonModel) {
        // person.Email = this.orderData.order.LeadSelf.Email;
        // person.MobilePhone = this.orderData.order.LeadSelf.MobilePhone;
    }

    getStandardPrice(): number {
        const products: ProductFamilyModel[] = this.productData.findProductFamilyList('Credit Block Add-On');
        if(this.productData != null){
            return this.productData.findStandardPrice(products, ProductFamilyType.Self);
        }else{
            return null;
        }
    }

    hasStandardPrice(): boolean {
        return this.getStandardPrice() !== null;
    }

    getPartnerPrice(): number {
        const products: ProductFamilyModel[] = this.productData.findProductFamilyList('Credit Block Add-On');
        const family: ProductFamilyModel = this.productData.findFamilyTypeFamily(products, ProductFamilyType.Self);
        if (family.Self && family.Self.PartnerPrice) {
            return family.Self.PartnerPrice;
        }
    }

    hasPartnerPrice(): boolean {
        return this.getPartnerPrice() !== null && this.getPartnerPrice() !== this.getStandardPrice() && this.getPartnerPrice() !== undefined;
    }

    onChangeLegalAuthority(person: PersonModel) {
        if (person.LegalAuthorityDate) {
            person.LegalAuthorityDate = (new Date()).toString(); // TODO: format?;
        } else {
            person.LegalAuthorityDate = '';
        }
    }

    getSpouseProduct(): ProductModel {
        const families: ProductFamilyModel[] = this.productData.findProductFamilyList('Credit Block Add-On');
        return this.productData.findProduct(families, ProductFamilyType.Spouse);
    }

    addSpouse() {
        if (!this.orderData.order.LeadSpouse) {
            this.orderData.order.LeadSpouse = new PersonModel();
        }
    }

    removeSpouse() {
        this.orderData.order.LeadSpouse = null;
    }

    showSpouse() {
        return isNullOrUndefined(this.orderData.order.LeadSpouse) === false;
    }

    showSelfSSN():boolean {
        return this.acceptCreditBlockAddition && this.envSvc.showCreditBlockUpsellNeedsSSN();
    }

    showSpouseSSN():boolean  {
        return  this.acceptCreditBlockSpouseAddition && this.envSvc.showCreditBlockUpsellNeedsSSN();
    }

    onClear() {
        this.clear.emit(this.formFields);
    }

    onAcceptCreditBlockChange(formField: FormFieldComponent) {
        if (formField && formField.name === 'CreditBlockStatus') {
            if (formField.value === '0') {
                this.acceptCreditBlockAddition = false;
            } else if (formField.value === '1') {
                this.acceptCreditBlockAddition = true;
            } else {
                console.error('invalid acceptCreditBlockAddition value ' + formField.value);
            }
        } else if (formField && formField.name === 'CreditBlockSpouseStatus') {

            if (formField.value === '0') {
                this.acceptCreditBlockSpouseAddition = false;
            } else if (formField.value === '1') {
                this.acceptCreditBlockSpouseAddition = true;
            } else {
                console.error('invalid acceptCreditBlockSpouseAddition value ' + formField.value);
            }
        }
    }

    showPanels() {
        return this.acceptCreditBlockAddition === undefined ? true : this.acceptCreditBlockAddition;
    }

    onAddCreditBlock() {
        let addOnOrder: OrderModel;
        this.allOrderLines = [];

        if (this.acceptCreditBlockSpouseAddition) {
            if (this.orderData.order.LeadSpouse) {
                if (!addOnOrder) {
                    addOnOrder = new OrderModel();
                }
                addOnOrder.LeadSpouse = this.orderData.order.LeadSpouse.deepCopy();
            }
        }

        let description: string;
        let products: ProductFamilyModel[] = this.productData.findProductFamilyList('Credit Block Add-On');

        products = products.filter(this.productData.filterCreditBlockSpecialPackageFromProductList);
        const productFamily = this.productData.findFamilyTypeFamily(products, ProductFamilyType.Self);
        let product = productFamily.Self;
        const familyName = productFamily.FamilyName;
        this.totalOrderLine = new OrderLineModel('', 'Total Due', 0, true);


        if (this.acceptCreditBlockAddition) {
            if (!addOnOrder) {
                addOnOrder = new OrderModel();
            }
            addOnOrder.hasUpsellSelf = true;
            addOnOrder.AuthorizeWIRE = true;
            addOnOrder.UnderstandSmartCredit = true;
            addOnOrder.LeadSelf = this.orderData.order.LeadSelf.deepCopy();
            if (this.orderData.order.LeadManager) {
                addOnOrder.LeadManager = this.orderData.order.LeadManager.deepCopy();
            }
            addOnOrder.LeadSelf.ProductName = product.ProductName;
            description = addOnOrder.LeadSelf.getFullName();
            this.totalOrderLine.Price += product.BasePrice;
            this.allOrderLines.push(new OrderLineModel(description, product.ProductName + ' <br><span class="small">(Annually)</span>', product.BasePrice, true));
        }

        if (addOnOrder && addOnOrder.LeadSpouse) {
            addOnOrder.hasUpsellSpouse = true;
            description = addOnOrder.LeadSpouse.getFullName();
            const spouseProductFamily = this.productData.findFamilyTypeFamily(products, ProductFamilyType.Spouse);

            product = spouseProductFamily.Spouse;
            addOnOrder.LeadSpouse.ProductName = product.ProductName;
            this.totalOrderLine.Price += product.BasePrice;
            let cleanedProductName = product.ProductName.replace(' - Spouse', '');
            this.allOrderLines.push(new OrderLineModel(description, cleanedProductName + ' <br><span class="small">(Annually)</span>', product.BasePrice, true));
        }

        this.orderData.additionalOrderLines = this.allOrderLines;
        this.orderData.addOnOrder = addOnOrder;
    }

    onStepEnter() {
        let productsSpecial: ProductFamilyModel[] = this.productData.findProductFamilyList('Credit Block Add-On');
        productsSpecial = productsSpecial.filter(this.productData.filterCreditBlockSpecialPackageFromProductList);

        const productFamilySpecial = this.productData.findFamilyTypeFamily(productsSpecial, ProductFamilyType.Self);
        const selfProductSpecial = productFamilySpecial.Self;
        this.selfPriceSpecial = selfProductSpecial.PartnerPrice;

        const spouseProductFamilySpecial = this.productData.findFamilyTypeFamily(productsSpecial, ProductFamilyType.Spouse);
        const spouseProductSpecial = spouseProductFamilySpecial.Spouse;
        this.spousePriceSpecial = spouseProductSpecial.PartnerPrice;

        let selfProductsRegular: ProductFamilyModel[];
        selfProductsRegular = this.productData.findProductFamilyList('Credit Block');

        const selfProductFamilyRegular = this.productData.findFamilyTypeFamily(selfProductsRegular, ProductFamilyType.Self);
        const selfProductRegular = selfProductFamilyRegular.Self;
        this.selfPriceRegular = selfProductRegular.StandardPrice;

        const spouseProductFamilyRegular = this.productData.findFamilyTypeFamily(selfProductsRegular, ProductFamilyType.Spouse);
        const spouseProductRegular = spouseProductFamilyRegular.Spouse;
        this.spousePriceRegular = spouseProductRegular.StandardPrice;

        const childProductFamilyRegular = this.productData.findFamilyTypeFamily(selfProductsRegular, ProductFamilyType.Child);
        const childProductRegular = childProductFamilyRegular.Child;
        this.childPriceRegular = childProductRegular.StandardPrice;

        this.childSpecialPrice = this.childPriceRegular;
    }

    onStepExit() {
        this.onAddCreditBlock();
    }
}
