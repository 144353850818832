import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
    name: 'redactPart'
})

export class RedactPartPipe implements PipeTransform {

    transform(value: string, count: number): string {
        try {
            if (count == 0) {
                return this.redact(value);
            } else if (count > 0) {
                let open = value.substr(0, count);
                let secret = value.substr(count);
                return open + this.redact(secret);
            } else {
                count = Math.max(count + value.length, 0);
                let secret = value.substr(0, count);
                let open = value.substr(count);
                return this.redact(secret) + open;
            }
        } catch (err) {
            console.log(err);
            return '';
        }
    }

    redact(value: string): string {
        return value.replace(/[A-Za-z0-9]/g, '*');
    }
}
