import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WizardPageComponent } from './pages/wizard-page/wizard-page';
import { TermsConditionsComponent } from './components/terms-conditions/terms-conditions.component';

const routes: Routes = [
    { path: '', redirectTo: 'enroll', pathMatch: 'full' },
    { path: 'enroll', component: WizardPageComponent },
    { path: 'terms-conditions', component: TermsConditionsComponent },
    { path: 'enroll/:partnerKey', component: WizardPageComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

export class AppRoutingModule {
}
