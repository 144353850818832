export class CouponModel {

  private _CouponId = '';
  private _CouponKey = '';
  private _DiscountPrice = null;

  constructor() {
  }

  static fromJson(json: any): CouponModel {
    let coupon = new CouponModel();
    coupon.setFromJson(json);
    return coupon;
  }

  setFromJson(json: any) {
    if (json.CouponId) {
      this.CouponId = json.CouponId;
    }
    if (json.CouponKey) {
      this.CouponKey = json.CouponKey;
    }
    if (json.DiscountPrice !== null) {
      this.DiscountPrice = json.DiscountPrice;
    }
  }

  get CouponId(): string {
    return this._CouponId;
  }
  set CouponId(CouponId: string) {
    this._CouponId = CouponId;
  }

  get CouponKey(): string {
    return this._CouponKey;
  }
  set CouponKey(CouponKey: string) {
    this._CouponKey = CouponKey.toUpperCase();
  }

  get DiscountPrice(): number {
    return this._DiscountPrice;
  }
  set DiscountPrice(DiscountPrice: number) {
    this._DiscountPrice = DiscountPrice;
  }

  deepCopy(): CouponModel {
    let coupon = new CouponModel();
    coupon.CouponId = this.CouponId;
    coupon.CouponKey = this.CouponKey;
    coupon.DiscountPrice = this.DiscountPrice;
    return coupon;
  }

  toOpenJson(): any {
    let json = {
      'CouponId': this.CouponId,
      'CouponKey': this.CouponKey,
      'DiscountPrice': this.DiscountPrice
    };
    return json;
  }

  toRedactedJson(): any {
    return this.toOpenJson();
  }
}
