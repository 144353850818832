import { Injectable } from '@angular/core';
import { Observable} from "rxjs/Observable";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import 'rxjs/add/operator/publish';
import {ErrorMessage} from "../models/error-message";
import { EnvironmentService } from './environment.service';

@Injectable()
export class NotificationService {

  private _notification: BehaviorSubject<ErrorMessage> = new BehaviorSubject(null);
  readonly notification$: Observable<ErrorMessage> = this._notification.asObservable().publish().refCount();

  private _paylinkRetrievedNotification: BehaviorSubject<any> = new BehaviorSubject(null);
  readonly _paylinkRetrievedNotification$: Observable<any> = this._paylinkRetrievedNotification.asObservable().publish().refCount();

  timeoutLength:number = 3000;

  constructor(public envSvc: EnvironmentService) { 
    this.timeoutLength = this.envSvc.getTimeoutLength(); 
  }

    notify(message) {
        this._notification.next(message);
        setTimeout(() => this._notification.next(null), this.timeoutLength);
    }

    notifyPaylinkRetrieved(message) {
      this._paylinkRetrievedNotification.next(message);
    }
}
 