import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
import { isNumber } from 'util';

@Directive({
    selector: '[appCurrencyValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: CurrencyValidatorDirective, multi: true }]
})

export class CurrencyValidatorDirective implements Validator {

    static readonly readableFormat = '';
    @Input('appCurrencyValidator') inputValue: any;
    logged = false;

    constructor(private currencyPipe: CurrencyPipe) {
    }

    validate(control: AbstractControl): { [key: string]: any } {
        if (this.isEnabled() && control.value) {
            const reducedValue = this.reduceValue(control.value);
            const formattedValue = this.formatValue(reducedValue);

            if (control.value !== formattedValue) {
                control.setValue(formattedValue);
            }

            if (!this.isFormatValid(formattedValue)) {
                return {appValue: true};
            }

        }
        return null;
    }

    isEnabled(): boolean {
        return this.inputValue !== undefined && this.inputValue !== false;
    }

    reduceValue(value: string): string {
        return value.toLowerCase().replace(/[^0-9.-]+/g, '');
    }

    isFormatValid(value: string): boolean {
        // return !!value.match(/^\d+\.\d\d$/) && value !== '$0.00';
        return !!value.match(/\$[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{2}/) && value != '$0.00';
    }

    // isValueValid(value: string) {
    //     return isNumber(value);
    // }

    formatValue(value: string): string {
        return this.currencyPipe.transform(+value);
    }
}
