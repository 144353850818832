import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import * as moment from 'moment';

@Directive({
  selector: '[appDateValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: DateValidatorDirective, multi: true}]
})

export class DateValidatorDirective implements Validator {

  static readonly readableFormat = 'mm/dd/yyyy';

  @Input('appDateValidator') inputValue: any;

  validate(control: AbstractControl): {[key: string]: any} {
    if (this.isEnabled() && control.value) {
      let reducedValue = this.reduceValue(control.value);
      let formattedValue = this.formatValue(reducedValue);
      if (!this.isFormatValid(formattedValue)) {
        return {'appFormat': DateValidatorDirective.readableFormat};
      }
      if (!this.isValueValid(formattedValue)) {
        return {'appValue': true};
      }
      if (control.value != formattedValue) {
        control.setValue(formattedValue);
      }
    }
    return null;
  }

  isEnabled(): boolean {
    return this.inputValue !== undefined && this.inputValue !== false;
  }

  reduceValue(value: string): string {
      if (typeof value !== 'string') {
          console.error(`value for date validator ('${value}, ${typeof value}') is not string, returning empty string`);
          return '';
      }
    return value.replace(/ /g, '').replace(/[\.\/-]/g, '-');
  }

  isFormatValid(value: string) {
    return value != '' &&  value != undefined;
  }

  isValueValid(value: string): boolean {
    return value !== "BAD_VALUE" && value !== "Invalid date";
  }

  isYearFirst(value: string):boolean{
    let possibleYear = value.substring(0,2);
    if(possibleYear === "19" || possibleYear === "20"){
      return true;
    }else{
      return false;
    }
  }
  formatDash(value: string): string {
    let pattern = "";
    if(this.isYearFirst(value)){
      pattern = "YYYY-MM-DD";
    }else{
      pattern = "MM-DD-YYYY";
    }
    let parseDate = moment(value, pattern);
    return parseDate.format("MM/DD/YYYY");
  }

  formatSlash(value: string): string {
    let pattern = "";
    if(this.isYearFirst(value)){
      pattern = "YYYY/MM/DD";
    }else{
      pattern = "MM/DD/YYYY";
    }
    let parseDate = moment(value, pattern);
    return parseDate.format("MM/DD/YYYY");
  }

  unFormatted(value: string): string {
    let pattern ="";
    if(this.isYearFirst(value)){
      pattern = "YYYYMMDD";
    }else{
      pattern = "MMDDYYYY";
    }
    let parseDate = moment(value, pattern);
    return parseDate.format("MM/DD/YYYY");
  }

  formatValue(value: string): string {
    let  formatValue= "";
    if(value.includes('-')){
      formatValue = this.formatDash(value);
    }else if(value.includes('/')){
      formatValue = this.formatSlash(value);
    }else if(value.length === 8){
      formatValue = this.unFormatted(value);
    }else if (value.length > 8 || value.length > 8){
      formatValue = "BAD_VALUE";
    }

    return formatValue;
   
  }
}
