import { RedactPartPipe } from '../pipes/redact-part';

export class AddressModel {

  private _Street = '';
  private _City = '';
  private _State = '';
  private _Zip = '';
  private _Country = 'United States';

  static readonly StateOptions = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming'
  ];

  constructor() {
  }

  get Street(): string {
    return this._Street;
  }
  set Street(Street: string) {
    this._Street = Street;
  }

  get City(): string {
    return this._City;
  }
  set City(City: string) {
    this._City = City;
  }

  get State(): string {
    return this._State;
  }
  set State(State: string) {
    this._State = State;
  }

  get Zip(): string {
    return this._Zip;
  }
  set Zip(Zip: string) {
    this._Zip = Zip;
  }

  get Country(): string{
    return this._Country;
  }

  set Country(Country: string) {
    this._Country = Country;
  }

  deepCopy(): AddressModel {
    let address = new AddressModel();
    address.Street = this.Street;
    address.City = this.City;
    address.State = this.State;
    address.Zip = this.Zip;
    address.Country = this.Country;
    return address;
  }

  toOpenJson(): any {
    let json = {
      'Street': this.Street,
      'City': this.City,
      'State': this.State,
      'Zip': this.Zip,
      'Country': this.Country
    };
    return json;
  }

  toRedactedJson(): any {
    let redactPart = new RedactPartPipe();
    let json = {
      'Street': redactPart.transform(this.Street, 1),
      'City': this.City,
      'State': this.State,
      'Zip': redactPart.transform(this.Zip, 2),
      'Country': this.Country
    };
    return json;
  }
}
