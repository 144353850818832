import { Injectable } from '@angular/core';

@Injectable()
export class CreditCardService {

    constructor() {
    }

    getCreditCardType(creditCardNumber: string): string {


        var cardType = (/^5[1-5]/.test(creditCardNumber)) ? "Mastercard" :
            (/^4/.test(creditCardNumber)) ? "Visa" :
                (/^3[47]/.test(creditCardNumber)) ? "American Express" :
                    (/^6011|65|64[4-9]|622(1(2[6-9]|[3-9]\d)|[2-8]\d{2}|9([01]\d|2[0-5]))/.test(creditCardNumber)) ? "Discover" :
                        "Unknown";

        return cardType;
    }

    cardValidation(creditCardNumber: string): boolean {
        if (creditCardNumber == null) {
            return false;
        }
        let backwards = '0';
        var multiplyx2 = "";
        var total = 0;
        /* For-loop going through the number. It begins at the second digit from the end.
	Then it adds to "backwards" every other digit going backwards through the number. */
        for (let i = creditCardNumber.length - 2; i >= 0; i -= 2) {
            backwards += creditCardNumber.charAt(i);

        }

        /* For-loop going through the digits in "backwards" and multiplying them by 2.
	The multiplied digits are stored in "multiplyx2".*/
        for (let i = 0; i < backwards.length; i++) {
            multiplyx2 += +backwards.charAt(i) * 2;
        }

        /* The digits in "multiplyx2" are added up and stored in "total". */
        for (let i = 0; i < multiplyx2.length; i++) {
            total += parseInt(multiplyx2.charAt(i));
        }

        /* Adding up the digits that have been left out. Starting with the third digit from the end.
	These digits are added to "total". */
        for (let i = creditCardNumber.length - 3; i >= 0; i -= 2) {
            total += parseInt(creditCardNumber.charAt(i));
        }

        /* Adding the last digit in the card number to "total".
	Could have done so in previous loop but just for the sake of it (that's how the Luhn algorithm goes) . */
        total += parseInt(creditCardNumber.charAt(creditCardNumber.length - 1))

        /* Testing if "total" can be divided by 10 without leaving a remainder. */
        if ((total % 10) == 0 && 'Unknown' !== this.getCreditCardType(creditCardNumber)) {
            return true;
        } else {
            return false;
        }
    }
}
