import { Component, OnInit, Output, EventEmitter, QueryList, ViewChildren } from '@angular/core';
import { FormFieldComponent } from '../../components/form-field/form-field'
import { PhoneValidatorDirective } from '../../directives/phone-validator';
import { OrderDataService } from '../../services/order-data';
import { PersonModel } from '../../models/person-model';

@Component({
  selector: 'app-wz-step-account-mgmt',
  templateUrl: './wz-step-account-mgmt.html',
  styleUrls: ['./wz-step-account-mgmt.scss']
})

export class WzStepAccountMgmtComponent implements OnInit {

  readonly phonePlaceholder = PhoneValidatorDirective.readableFormat;

  @ViewChildren(FormFieldComponent) formFields: QueryList<FormFieldComponent>;
  @Output() clear = new EventEmitter();
  managedType = '';

  constructor(
    public orderData: OrderDataService) {
  }

  ngOnInit() {
  }

  onChange(formField) {
    if (formField && formField.name == 'ManagedType') {
      if (formField.value == 'Manager') {
        this.orderData.order.LeadManager = new PersonModel();
      } else {
        this.orderData.order.LeadManager = null;
      }
    }
  }

  onClear() {
    this.clear.emit(this.formFields);
  }
}
