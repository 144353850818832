import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import * as moment from 'moment';

@Directive({
  selector: '[appMonthYearValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: MonthYearValidatorDirective, multi: true}]
})

export class MonthYearValidatorDirective implements Validator {

  static readonly readableFormat = 'mm/yyyy';

  @Input('appMonthYearValidator') inputValue: any;

  validate(control: AbstractControl): {[key: string]: any} {
    if (this.isEnabled() && control.value) {
      let reducedValue = this.reduceValue(control.value);
      if (!this.isFormatValid(reducedValue)) {
        return {'appFormat': MonthYearValidatorDirective.readableFormat};
      }
      if (!this.isValueValid(reducedValue)) {
        return {'appValue': true};
      }
      let formattedValue = this.formatValue(reducedValue);
      if (control.value != formattedValue) {
        control.setValue(formattedValue);
      }
    }
    return null;
  }

  isEnabled(): boolean {
    return this.inputValue !== undefined && this.inputValue !== false;
  }

  reduceValue(value: string): string {
    return value.replace(/ /g, '').replace(/[\.\/-]/g, '-');
  }

  isFormatValid(value: string) {
    return !!value.match(/^[0-9]{1,2}-[0-9]{4}$/);
  }

  isValueValid(value: string): boolean {
    let [month, year] = value.split('-');
    let minYear = moment().year();
    if (+year < minYear || +year > minYear + 20) {
      return false;
    }
    let minMonth = (+year == minYear ? moment().month(): 0);
    if (+month < minMonth || +month > 12) {
      return false;
    }
    return true;
  }

  formatValue(value: string): string {
    let [month, year] = value.split('-');
        return month.padStart(2, '0') + '/' + year;
  }
}
