import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter, Input,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { FormFieldComponent } from '../form-field/form-field';
import { PhoneValidatorDirective } from '../../directives/phone-validator';
import { SsnValidatorDirective } from '../../directives/ssn-validator';
import { DateValidatorDirective } from '../../directives/date-validator';
import { OrderDataService } from '../../services/order-data';
import { PersonAccountDataService } from '../../services/person-account';
import { PersonModel } from '../../models/person-model';
import { LeadModel } from '../../models/lead-model';
import { LeadDataService } from '../../services/lead.service';
import { EnvironmentService } from '../../services/environment.service';
import {AddressModel} from "../../models/address-model";
import {AddressComponent} from "../address/address.component";

@Component({
    selector: 'app-wz-step-your-info',
    templateUrl: './wz-step-your-info.html',
    styleUrls: ['./wz-step-your-info.scss']
})

export class WzStepYourInfoComponent implements OnInit, AfterViewInit {

    readonly phonePlaceholder = PhoneValidatorDirective.readableFormat;
    readonly ssnPlaceholder = SsnValidatorDirective.readableFormat;
    readonly datePlaceholder = DateValidatorDirective.readableFormat;
    @Output() clear = new EventEmitter();
    @ViewChildren(FormFieldComponent) formFields: QueryList<FormFieldComponent>;
    @ViewChildren(AddressComponent) AddressFields: QueryList<AddressComponent>;
    @ViewChild('firstName') firstNameField: FormFieldComponent;
    @ViewChild('middleName') middleNameField: FormFieldComponent;
    @ViewChild('lastName') lastNameField: FormFieldComponent;
    @ViewChild('suffix') suffixField: FormFieldComponent;
    @ViewChild('mobilePhone') mobilePhoneField: FormFieldComponent;
    @ViewChild('teamEmail') teamEmailField: FormFieldComponent;
    @ViewChild('email') emailField: FormFieldComponent;
    @ViewChild('ssn') ssnField: FormFieldComponent;
    @ViewChild('birthdate') birthdateField: FormFieldComponent;
    @ViewChild('currentAddress')  currentAddressField: AddressComponent;
    @ViewChild('previousAddress') perviousAddressField:AddressComponent;

    private personInformationDefault = 'Your Information';
    private personInformationAccountManaged = 'Client Information';
    showClientCaptureBlock = false;
    personAccount: PersonModel;
    lead: LeadModel;

    constructor(
        public orderData: OrderDataService,
        public personAccountData: PersonAccountDataService,
        public leadData: LeadDataService,
        private changeDetectorRef: ChangeDetectorRef,
        protected envSvc: EnvironmentService) {
    }

    private _firstNameText: string;

    get firstNameText(): string {
        return this._firstNameText;
    }

    set firstNameText(value: string) {
        this._firstNameText = value;
        this.orderData.order.LeadSelf.FirstName = value;
    }

    private _middleNameText: string;

    get middleNameText(): string {
        return this._middleNameText;
    }

    set middleNameText(value: string) {
        this._middleNameText = value;
        this.orderData.order.LeadSelf.MiddleName = value;
    }

    private _lastNameText: string;

    get lastNameText(): string {
        return this._lastNameText;
    }

    set lastNameText(value: string) {
        this._lastNameText = value;
        this.orderData.order.LeadSelf.LastName = value;
    }

    private _suffixText: string;

    get suffixText(): string {
        return this._suffixText;
    }

    set suffixText(value: string) {
        this._suffixText = value;
        this.orderData.order.LeadSelf.Suffix = value;
    }

    private _teamEmailText: string;

    get teamEmailText(): string {
        return this._teamEmailText;
    }

    set teamEmailText(value: string) {
        this._teamEmailText = value;
        this.orderData.order.LeadSelf.TeamEmail = value;
    }

    private _emailText: string;

    get emailText(): string {
        return this._emailText;
    }

    set emailText(value: string) {
        this._emailText = value;
        this.orderData.order.LeadSelf.Email = value;
    }

    private _ssnText: string;

    get ssnText(): string {
        return this._ssnText;
    }

    set ssnText(value: string) {
        this._ssnText = value;
        this.orderData.order.LeadSelf.Ssn = value;
    }

    private _birthdateText: string;

    get birthdateText(): string {
        return this._birthdateText;
    }

    set birthdateText(value: string) {
        this._birthdateText = value;
        this.orderData.order.LeadSelf.Birthdate = value;
    }

    private _mobilePhoneText: string;

    get mobilePhoneText(): string {
        return this._mobilePhoneText;
    }

    set mobilePhoneText(value: string) {
        this._mobilePhoneText = value;
        this.orderData.order.LeadSelf.MobilePhone = value;
    }

    private _personInformation = this.personInformationDefault;

    get personInformation(): string {
        if (this.orderData.order.isAccountManaged()) {
            return this.personInformationAccountManaged;
        }
        return this._personInformation;
    }

    set personInformation(value: string) {
        this._personInformation = value;
    }

    private _currentAddressData = new AddressModel();

    get CurrentAddressData(): AddressModel {
        return this._currentAddressData;
    }

    set CurrentAddressData(value: AddressModel) {
        this.orderData.order.LeadSelf.CurrentAddress = value;
        this._currentAddressData = value;
    }

    private _previousAddressData = new AddressModel();
    get PreviousAddressData(): AddressModel{
        return this._previousAddressData;
    }
    set PreviousAddressData(value:AddressModel){
        this.orderData.order.LeadSelf.PreviousAddress = value;
        this._previousAddressData = value;
    }
    ngAfterViewInit() {
        //This service is used when a lead is known
        this.leadData.leadDataAsyncSubject.subscribe((lead: LeadModel) => {
            if (lead) {
                this.lead = lead;
                const dataObject: {
                    [i: string]: {
                        field: FormFieldComponent,
                        data: string,
                        classRepresentation: any
                    }
                } = { // todo: better name
                  firstName: {
                    field: this.firstNameField,
                    data: this.lead.FirstName,
                    classRepresentation: this.firstNameText
                  },
                  middleName: {
                    field: this.middleNameField,
                    data: this.lead.MiddleName,
                    classRepresentation: this.middleNameText
                  },
                  lastName: {
                    field: this.lastNameField,
                    data: this.lead.LastName,
                    classRepresentation: this.lastNameText
                  },
                  suffix: {
                    field: this.suffixField,
                    data: this.lead.Suffix,
                    classRepresentation: this.suffixText
                  },
                  mobilePhone: {
                    field: this.mobilePhoneField,
                    data: this.lead.MobilePhone,
                    classRepresentation: this.mobilePhoneText
                  },
                  email: {
                    field: this.emailField,
                    data: this.lead.Email,
                    classRepresentation: this.emailText
                  },
                  ssn: {
                    field: this.ssnField,
                    data: this.lead.Ssn,
                    classRepresentation: this.ssnText
                  },
                  birthdate: {
                    field: this.birthdateField,
                    data: this.lead.Birthdate,
                    classRepresentation: this.birthdateText
                  }
                };

                for (const obj in dataObject) { // todo: better name
                    // todo: in dire need of refactoring
                    if (obj) {
                        const x = dataObject[obj];
                        const field: FormFieldComponent = x.field;
                        const data: string = x.data;
                        if (field !== undefined && data !== undefined) {
                            switch (obj) {
                                case 'firstName':
                                    this.firstNameText = data;
                                    break;
                                case 'middleName':
                                    this.middleNameText = data;
                                    break;
                                case 'lastName':
                                    this.lastNameText = data;
                                    break;
                                case 'suffix':
                                    this.suffixText = data;
                                    break;
                                case 'teamEmail':
                                    this.teamEmailText = data;
                                    break;
                                case 'email':
                                    this.emailText = data;
                                    break;
                                case 'ssn':
                                    this.ssnText = data;
                                    break;
                                case 'mobilePhone':
                                    this.mobilePhoneText = data;
                                    break;
                                case 'birthdate':
                                    this.birthdateText = data;
                                    break;
                                default:
                                    break;
                            }
                            this.changeDetectorRef.detectChanges();
                        }
                    }
                }
            } else {

            }
        });

        //This service is used when a personAccount is known
        this.personAccountData.personAccountAsyncSubject.subscribe((personAccount: PersonModel) => {
            if (personAccount) {
                this.personAccount = personAccount;
                const dataObjectSimple = this.simpleFieldPullPersonAccount();
                const dataObjectComplex = this.complexFieldPullPersonAccount();
                this.simpleDatatPushPersonAccount(dataObjectSimple);
                //todo: add complexDataPushPersonAccount(dataObjectComplex);
            } else {

            }
        });
    }

    private simpleDatatPushPersonAccount(dataObject: { [p: string]: { field: FormFieldComponent; data: string; classRepresentation: any } }) {
        for (const obj in dataObject) { // todo: better name
            if (obj) {
                const x = dataObject[obj];
                const field: FormFieldComponent = x.field;
                const data: string = x.data;
                if (field !== undefined && data !== undefined) {
                    switch (obj) {
                        case 'firstName':
                            this.firstNameText = data;
                            break;
                        case 'middleName':
                            this.middleNameText = data;
                            break;
                        case 'lastName':
                            this.lastNameText = data;
                            break;
                        case 'suffix':
                            this.suffixText = data;
                            break;
                        case 'teamEmail':
                            this.teamEmailText = data;
                            break;
                        case 'email':
                            this.emailText = data;
                            break;
                        case 'ssn':
                            this.ssnText = data;
                            break;
                        case 'mobilePhone':
                            this.mobilePhoneText = data;
                            break;
                        case 'birthdate':
                            this.birthdateText = data;
                            break;
                        default:
                            break;
                    }
                    this.changeDetectorRef.detectChanges();
                }
            }
        }
    }

    private simpleFieldPullPersonAccount() {
        const dataObject: {
            [i: string]: {
                field: FormFieldComponent,
                data: string,
                classRepresentation: any
            }
        } = { // todo: better name
            firstName: {
                field: this.firstNameField,
                data: this.personAccount.FirstName,
                classRepresentation: this.firstNameText
            },
            middleName: {
                field: this.middleNameField,
                data: this.personAccount.MiddleName,
                classRepresentation: this.middleNameText
            },
            lastName: {
                field: this.lastNameField,
                data: this.personAccount.LastName,
                classRepresentation: this.lastNameText
            },
            suffix: {
                field: this.suffixField,
                data: this.personAccount.Suffix,
                classRepresentation: this.suffixText
            },
            mobilePhone: {
                field: this.mobilePhoneField,
                data: this.personAccount.MobilePhone,
                classRepresentation: this.mobilePhoneText
            },
            teamEmail: {
                field: this.teamEmailField,
                data: this.personAccount.TeamEmail,
                classRepresentation: this._teamEmailText
            },
            email: {
                field: this.emailField,
                data: this.personAccount.Email,
                classRepresentation: this.emailText
            },
            ssn: {
                field: this.ssnField,
                data: this.personAccount.Ssn,
                classRepresentation: this.ssnText
            },
            birthdate: {
                field: this.birthdateField,
                data: this.personAccount.Birthdate,
                classRepresentation: this.birthdateText
            },
        };
        return dataObject;
    }
    ngOnInit() {
        this.showClientCapture();
        this.setCyberSweepDefaults();
    }
    onClear() {
        this.clear.emit(this.formFields);
    }
    setCyberSweepDefaults(){
        if(this.isCyberSweep()){
            this.ssnText  = '000-00-0000';
        }
    }
    
    isCyberSweep() {
        let cyberSweepResult = false;
        if (this.envSvc.getProductFromSubdomain() === 'cybersweep') {
            cyberSweepResult = true;
        }
        return cyberSweepResult;
    }
    
    showClientCapture() {
            return this.isCyberSweep();
    }
    
    private complexFieldPullPersonAccount() {
        const dataObject: {
            [i: string]: {
                field: AddressComponent,
                data: AddressModel,
                classRepresentation: any
            }
        } = { // todo: better name
            CurrentAddress: {
                field: this.currentAddressField,
                data: this.personAccount.CurrentAddress,
                classRepresentation: this.CurrentAddressData
            }
        }
        return dataObject;
    }
}
