export class ProductModel {

    constructor() {
    }

    private _ProductId = '';

    get ProductId(): string {
        return this._ProductId;
    }

    set ProductId(ProductId: string) {
        this._ProductId = ProductId;
    }

    private _ProductName = '';

    get ProductName(): string {
        return this._ProductName;
    }

    set ProductName(ProductName: string) {
        this._ProductName = ProductName;
    }

    private _StandardPrice = null;

    get StandardPrice(): number {
        return this._StandardPrice;
    }

    set StandardPrice(StandardPrice: number) {
        this._StandardPrice = StandardPrice;
    }

    private _PartnerPrice = null;

    get PartnerPrice(): number {
        return this._PartnerPrice;
    }

    set PartnerPrice(PartnerPrice: number) {
        this._PartnerPrice = PartnerPrice;
    }

    private _MinAccount = null;

    get MinAccount(): any {
        return this._MinAccount;
    }

    set MinAccount(value: any) {
        this._MinAccount = value;
    }

    private _MaxAccount = null;

    get MaxAccount(): any {
        return this._MaxAccount;
    }

    set MaxAccount(value: any) {
        this._MaxAccount = value;
    }

    get BasePrice(): number {
        return (this.PartnerPrice !== null ? this.PartnerPrice : this.StandardPrice);
    }

    static fromJson(json: any): ProductModel {
        let product = new ProductModel();
        product.setFromJson(json);
        return product;
    }

    setFromJson(json: any) {
        if (json.ProductId) {
            this.ProductId = json.ProductId;
        }
        if (json.ProductName) {
            this.ProductName = json.ProductName;
        }
        if (json.StandardPrice !== null) {
            this.StandardPrice = json.StandardPrice;
        }
        if (json.PartnerPrice !== null) {
            this.PartnerPrice = json.PartnerPrice;
        }
        if (json.MinAccount !== null) {
            this.MinAccount = json.MinAccount;
        }
        if (json.MaxAccount !== null) {
            this.MaxAccount = json.MaxAccount;
        }
    }

    deepCopy(): ProductModel {
        let product = new ProductModel();
        product.ProductId = this.ProductId;
        product.ProductName = this.ProductName;
        product.StandardPrice = this.StandardPrice;
        product.PartnerPrice = this.PartnerPrice;
        product.MinAccount = this.MinAccount;
        product.MaxAccount = this.MaxAccount;
        return product;
    }
}

export enum ProductFamilyPicklistValues {
    CreditBlock = 'Credit Block',
    CreditRepair = 'Credit Repair',
    CreditDevelopment = 'Credit Development',
    CreditDevWithPremiumCreditRepair = 'Credit Dev with Premium Credit Repair',
    CreditReport = 'Credit Report',
    CreditBlockUpsell = 'Credit Block Add-On',
    CreditRepairMonthly = 'Credit Repair Monthly',
    CyberSweep = 'Cyber Sweep',
    ChexSystem = 'ChexSystem'

}
