import { Injectable, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';

/**
 * A service to provide information about the runtime environment
 */
@Injectable()
export class EnvironmentService  {

    config = environment.config;

    constructor() {
    }

    /**
     * 
     * @returns timeout length
     */
    getTimeoutLength():number{
        return environment.config.timeout;
    }



    /**
     * Fetch product family name for domain
     */
    getProductFamilyFromSubdomain(): string {
        const product = this.getProductFromSubdomain();
        for (const p in environment.config.products) {
            if (product === p) {
                return environment.config.products[product];
            }
        }
    }

    /**
     * Determines the current product based on the routing subdomain
     */
    getProductFromSubdomain(): string {
        const hostname: string = location.hostname;
        const split: string[] = hostname.split('.');
        const subdomain = split[0];
        for (const p in environment.config.products) {
            if (subdomain && subdomain === p) {
                return subdomain;
            }
        }
        throw new Error(`product not found for url "${hostname}"`);
    }

    /**
     * refactor these two, duplicate code
     */
    showDemographicPanel() {
        const demogProducts: string[] = environment.config.productRequiresDemographicInfo;
        const product = this.getProductFromSubdomain();
        return demogProducts.indexOf(product) !== -1;
    }

    /**
     *
     */
    showCreditBlockPanel() {
        const creditBlockUpsellProducts: string[] = environment.config.productHasCreditBlockUpsell;
        const product = this.getProductFromSubdomain();
        return creditBlockUpsellProducts.indexOf(product) !== -1;
    }

    /**
     * Determine whether or not to show ChexSystems panel
     */
    showChexSystemsPanel() {
        const ChexSystemsProducts: string[] = environment.config.productHasChexSystemUpsell;
        const product = this.getProductFromSubdomain();
        return ChexSystemsProducts.indexOf(product) !== -1;
    }
    /***
     * 
     */
     showCreditBlockUpsellNeedsSSN():boolean{
        const creditBlockUpsellNeedsSSN: string[] = environment.config.creditBlockUpsellNeedsSSN;
        const product = this.getProductFromSubdomain();
        return creditBlockUpsellNeedsSSN.indexOf(product) !== -1;
    }
}
