import {Component, OnInit} from '@angular/core';
import {NotificationService} from '../../services/notification.service';
import {EnvironmentService} from '../../services/environment.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-wz-step-finish',
    templateUrl: './wz-step-finish.html',
    styleUrls: ['./wz-step-finish.scss']
})

export class WzStepFinishComponent implements OnInit {
    constructor(private notificationService: NotificationService,
                protected envSvc: EnvironmentService,
                private spinner: NgxSpinnerService) {
    }

    private readonly NO_TRANSACTION = 'no_transaction'
    private readonly CYBERSWEEP = 'cybersweep'
    private readonly CREDIT_BLOCK = 'creditblock';
    private _description = 'Thank you for starting your identity protection with Credit Block.\n' +
        '          We will process your request and will let you know if we require additional information.';

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    private _title = ' Thank You';

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    private _paylinkUrl = '';

    get paylinkUrl(): string {
        return this._paylinkUrl;
    }
    set paylinkUrl(value: string) {
        this._paylinkUrl = value;
    }

    ngOnInit() {
        this.spinner.show();
        this.notificationService.notification$.subscribe(message => {
            if (message) {
                this.spinner.hide();
                console.log(message);
                this._description = message.text;
                this._title = message.title;
            }
            else {
                // No message received, the spinner should keep spinning.
            }
        });

        this.notificationService._paylinkRetrievedNotification$.subscribe(paylinkUrl => {
                if (paylinkUrl) {
                    this.spinner.hide();
                    this.paylinkUrl = paylinkUrl;
                }
            }
        );
    }

    isCyberSweep() {
        let cyberSweepResult = false;
        if (this.envSvc.getProductFromSubdomain() === this.CYBERSWEEP) {
            cyberSweepResult = true;
        }
        return cyberSweepResult;
    }

    isCreditBlock() {
        let creditBlockResult = false;
        if (this.envSvc.getProductFromSubdomain() === this.CREDIT_BLOCK) {
            creditBlockResult = true;
        }
        return creditBlockResult;
    }

    isProcessOrderVisible() {
        return this.paylinkUrl != this.NO_TRANSACTION
    }

    processOrder() {
        window.open(this.paylinkUrl, '_blank');
    }
}
