import { Injectable } from '@angular/core';
import { OrderModel } from '../models/order-model';
import { OrderLineModel } from '../models/order-line-model';

@Injectable()
export class OrderDataService {

  order = new OrderModel();
  addOnOrder: OrderModel;
  additionalOrderLines: OrderLineModel[] = [];

  constructor() {
  }
}
