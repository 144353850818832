import { ProductModel } from './product-model';
import { ProductFamilyModel } from './product-family-model';

export class ProductFamilyListModel {

    private _FamilyList = new Array<ProductFamilyModel>();
    private _NameMap: { [name: string]: number } = {};

    constructor() {
    }

    private _hasFreeCreditReport = 'NO';

    get HasFreeCreditReport():string{
        return this._hasFreeCreditReport;
    }
    set HasFreeCreditReport(value:string){
        this._hasFreeCreditReport = value.toUpperCase();;
    }

    private _PartnerKey = '';

    get PartnerKey(): string {
        return this._PartnerKey;
    }

    set PartnerKey(PartnerKey: string) {
        this._PartnerKey = PartnerKey;
    }

    private _PricebookId = '';

    get PricebookId(): string {
        return this._PricebookId;
    }

    set PricebookId(PricebookId: string) {
        this._PricebookId = PricebookId;
    }

    get Families(): Array<ProductFamilyModel> {
        return this._FamilyList;
    }

    static fromJson(json: any): ProductFamilyListModel {

        let productFamilyList = new ProductFamilyListModel();
        productFamilyList.setFromJson(json);
        return productFamilyList;
    }

    setFromJson(json: any) {
        if(json.PartnerFreeCreditReport){
            this.HasFreeCreditReport = json.PartnerFreeCreditReport;
        }
        if (json.PartnerKey) {
            this.PartnerKey = json.PartnerKey;
        }
        if (json.PricebookId) {
            this.PricebookId = json.PricebookId;
        }
        if (json.Products && json.Products instanceof Array) {
            this._FamilyList.length = 0;
            for (const json_i of json.Products) {
                if (json_i.ProductFamily) {
                    let productFamily = this.getFamilyByName(json_i.ProductFamily);
                    productFamily = this.addFamily(new ProductFamilyModel(json_i.ProductFamily));
                    const product = ProductModel.fromJson(json_i);
                    if (json_i.IsForSpouse) {
                        productFamily.Spouse = product;
                    } else if (json_i.IsForChild) {
                        productFamily.Child = product;
                    } else {
                        productFamily.Self = product;
                    }
                }
            }
        }
    }

    getFamilyByName(name: string, familyType?: ProductFamilyType | string): ProductFamilyModel {
        const index = this._NameMap[name];
        if (index !== undefined) {

            if (familyType) {
                const family = this._FamilyList.find((fam) => {
                    return fam.FamilyName === name && fam[familyType] != null;
                });


                return family;
            } else {
                return this._FamilyList[index];
            }
        } else {
            return null;
        }
    }

    getAllFamiliesByName(name: string): ProductFamilyModel[] {
        const familiesByName: ProductFamilyModel[] = [];
        const index = this._NameMap[name];

        // return [];
        if (index !== undefined) {
            for (const family of this._FamilyList) {
                if (family.FamilyName === name) {
                    familiesByName.push(family);
                }
            }
            return familiesByName;
        } else {
            return null;
        }
    }

    addFamily(productFamily: ProductFamilyModel): ProductFamilyModel {
        let index = this._FamilyList.length;
        this._NameMap[productFamily.FamilyName] = index;
        this._FamilyList[index] = productFamily;
        return productFamily;
    }

    deepCopy(): ProductFamilyListModel {
        let productFamilyList = new ProductFamilyListModel();
        productFamilyList.PartnerKey = this.PartnerKey;
        productFamilyList.PricebookId = this.PricebookId;
        for (let productFamily of this.Families) {
            productFamilyList.addFamily(productFamily.deepCopy());
        }
        return productFamilyList;
    }
}

export enum ProductFamilyType {
    Self = 'Self',
    Spouse = 'Spouse',
    Child = 'Child'
}
