import { Injectable } from '@angular/core';
import { ApiGatewayService } from './api-gateway';
import { ProductFamilyModel } from '../models/product-family-model';
import { ProductFamilyListModel, ProductFamilyType } from '../models/product-family-list-model';
import { EnvironmentService } from './environment.service';
import { ProductModel } from '../models/product-model';
import { PersonModel } from '../models/person-model';

@Injectable()
export class ProductDataService {
  // TODO: refactor this whole service. has circular logic and highly cyclomatic code.

    familyList: ProductFamilyListModel = null;
    premiumCreditRepairFamilies = null;
    ifPartnerKey = null;

    constructor(
        private apiGateway: ApiGatewayService,
        public envSvc: EnvironmentService) {
        this.familyList = new ProductFamilyListModel();
    }

    private _productFamilyName; // default to Credit Block

    get productFamilyName(): string {
        return this._productFamilyName;
    }

    set productFamilyName(value: string) {
        this._productFamilyName = value;
    }

    buildFamilyList(partnerKey: string): Promise<ProductFamilyListModel> {
        this.productFamilyName = this.envSvc.getProductFamilyFromSubdomain();

        return new Promise((resolve, reject) => {
            this.apiGateway.getProductFamilyList(partnerKey).then((familyList) => {
                this.familyList = familyList;
                resolve(this.familyList);
            }).catch((err) => {
                reject(err);
            });
        });
    }

    getProductPricing(familyName: string, personAccount: PersonModel, productFamilyType: ProductFamilyType, hasPartnerKey: boolean): number {
        const products = this.findProductFamilyList(familyName, personAccount);
        if (hasPartnerKey) {
            return this.findPartnerPrice(products, productFamilyType);
        }
        else {
            return this.findStandardPrice(products, productFamilyType);
        }
    }

    findProductFamilyList(familyName: string, personAccount?: PersonModel): ProductFamilyModel[] {
        let numAcctsString;
        let numAccts;

        if (personAccount) {
            numAcctsString = personAccount.TotalCreditCardAccounts;
            numAccts = +numAcctsString;
        } else {
            numAccts = 0;
        }

        if (familyName === 'Credit Repair' || familyName === 'Credit Dev with Premium Credit Repair') {
            return this.findCreditRepairProducts(numAccts, familyName);
        } else {
            if (this.familyList !== null) {
                return this.familyList.getAllFamiliesByName(familyName);
            } else {
                return [];
            }
        }
    }

    findCreditRepairProducts(numAccts: number, familyName: string): ProductFamilyModel[] {
        if (this.familyList !== null) {
            const creditRepairFamilies: ProductFamilyModel[] = this.familyList.getAllFamiliesByName(familyName);

            if (numAccts === 0) {
                return creditRepairFamilies.filter(this.filterCreditRepairRegularProductFamilies);
            }
            const premium = creditRepairFamilies.filter(this.filterCreditRepairPremiumProductFamilies);
            return this.compareAccounts(premium, numAccts);
        } else {
            return [];
        }
    }

    compareAccounts(creditRepairFamilies: ProductFamilyModel[], numAccts: number): ProductFamilyModel[] {
        const found: ProductFamilyModel[] = [];

        for (const creditRepairFamily of creditRepairFamilies) {
            if (creditRepairFamily.Self && creditRepairFamily.Self.MinAccount && creditRepairFamily.Self.MaxAccount) {
                if (numAccts >= creditRepairFamily.Self.MinAccount &&
                    numAccts <= creditRepairFamily.Self.MaxAccount) {
                    found.push(creditRepairFamily);
                }
            }
            if (creditRepairFamily.Spouse && creditRepairFamily.Spouse.MinAccount && creditRepairFamily.Spouse.MaxAccount) {
                if (numAccts >= creditRepairFamily.Spouse.MinAccount &&
                    numAccts <= creditRepairFamily.Spouse.MaxAccount) {
                    found.push(creditRepairFamily);
                }
            }
        }
        this.premiumCreditRepairFamilies = found;
        return this.premiumCreditRepairFamilies;

    }

    filterCreditRepairPremiumProductFamilies(productFamily: ProductFamilyModel): boolean {
        if (productFamily.Self && productFamily.Self.MinAccount && productFamily.Self.MaxAccount) {
            return true;
        }

        if (productFamily.Spouse && productFamily.Spouse.MinAccount && productFamily.Spouse.MaxAccount) {
            return true;
        }
        return false;
    }

    filterCreditRepairRegularProductFamilies(productFamily: ProductFamilyModel): boolean {
        if (productFamily.Self && productFamily.Self.MinAccount == null && productFamily.Self.MaxAccount == null) {
            return true;
        }

        if (productFamily.Spouse && productFamily.Spouse.MinAccount == null && productFamily.Spouse.MaxAccount == null) {
            return true;
        }
        return false;
    }

    findProduct(products: ProductFamilyModel[], productFamilyType: ProductFamilyType): ProductModel {
        if (this.findFamilyTypeFamily(products, productFamilyType)) {
            return this.findFamilyTypeFamily(products, productFamilyType)[productFamilyType];
        }

        return null;
    }

    findStandardPrice(products: ProductFamilyModel[], productFamilyType: ProductFamilyType): number {
        var product = this.findProduct(products, productFamilyType)
        if(product != null) {
            return product.StandardPrice;
        }else{
            return null;
        }
    }

    findPartnerPrice(products: ProductFamilyModel[], productFamilyType: ProductFamilyType): number {
        var product = this.findProduct(products, productFamilyType)
        if(product != null) {
            return product.PartnerPrice;
        }else{
            return null;
        }
    }

    findFamilyTypeFamily(products: ProductFamilyModel[], productFamilyType: ProductFamilyType): ProductFamilyModel {
        var productResult: ProductFamilyModel = null;
        if(products !== null){
            for (let product of products) {
                if (product[productFamilyType] !== null) {
                    productResult = product;
                    break;
                }
            }
        }
        return productResult;
    }

    filterCreditBlockSpecialPackageFromProductList(productFamily: ProductFamilyModel) {
        if (productFamily.Self && productFamily.Self.ProductName.includes('Credit Block Add-On')) {
            return true;
        }

        if (productFamily.Spouse && productFamily.Spouse.ProductName.includes('Credit Block Add-On')) {
            return true;
        }

        return false;
    }

    filterProductFamiliesByType(products: ProductFamilyModel[], productFamilyType: ProductFamilyType): ProductFamilyModel[] {
        return products.filter((fam) =>
            fam[productFamilyType] !== null
        );
    }

    findMonthlyProductFamily(productFamiliesFilteredByType: ProductFamilyModel[], productFamilyType: ProductFamilyType) {
        return productFamiliesFilteredByType.find((fam) =>
            fam[productFamilyType].ProductName !== null && fam[productFamilyType].ProductName.includes('Monthly')
        );
    }

    findMonthlyPrice(products: ProductFamilyModel[], productFamilyType: ProductFamilyType, partnerKey: string): number {
        let monthlyProductFamily: ProductFamilyModel;
        let monthlyProduct: ProductModel;

        const productFamiliesFilteredByType = this.filterProductFamiliesByType(products, productFamilyType);

        if (productFamiliesFilteredByType.length > 0) {
            monthlyProductFamily = this.findMonthlyProductFamily(productFamiliesFilteredByType, productFamilyType);
        } else {
            return null;
        }

        if (monthlyProductFamily != null) {
            monthlyProduct = monthlyProductFamily[productFamilyType];
        } else {
            return null;
        }

        if (monthlyProduct != null) {
            if (partnerKey){
                return monthlyProduct.PartnerPrice
            }
            else {
                return monthlyProduct.StandardPrice;
            }

        } else {
            return null;
        }
    }

    shouldFetchMonthlyPrice() {
        const productFamily: string = this.envSvc.getProductFromSubdomain();

        switch (productFamily) {
            case 'creditrepairwithdevelopment': // fallthrough case
            case 'creditrepair':
                return true;
            default:
                return false;
        }
    }
}
