import { Injectable } from '@angular/core';
import { ApiGatewayService } from './api-gateway';
import { PersonModel } from '../models/person-model';
import { AsyncSubject } from 'rxjs';
import { LeadModel } from '../models/lead-model';

@Injectable()
export class LeadDataService {
    public leadDataAsyncSubject: AsyncSubject<LeadModel> = new AsyncSubject();

    constructor(
        private apiGateway: ApiGatewayService) {
    }

    getLead(leadId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.apiGateway.getLead(leadId).then((personAccount) => {
                this.notify(personAccount);

                this.leadDataAsyncSubject.complete();
                resolve();
            }).catch((err) => {
                reject(err);
            });
        });
    }

    notify(message: any) {
        this.leadDataAsyncSubject.next(message);
    }
}
