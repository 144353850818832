import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.html',
  styleUrls: ['./footer.scss']
})

export class FooterComponent implements OnInit {

  readonly startYear = 2018;
  yearsRange: string;

  constructor() {
    let currentYear = (new Date()).getFullYear();
    this.yearsRange = (currentYear > this.startYear ? this.startYear + '-' : '') + currentYear;
  }

  ngOnInit() {
  }
}
