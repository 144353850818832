import { ProductModel } from './product-model';

export class ProductFamilyModel {

  private _FamilyName = '';
  private _Self: ProductModel = null;
  private _Spouse: ProductModel = null;
  private _Child: ProductModel = null;

  constructor(FamilyName: string) {
    this._FamilyName = FamilyName;
  }

  get FamilyName(): string {
    return this._FamilyName;
  }

  get Self(): ProductModel {
    return this._Self;
  }
  set Self(Self: ProductModel) {
    this._Self = Self;
  }

  get Spouse(): ProductModel {
    return this._Spouse;
  }
  set Spouse(Spouse: ProductModel) {
    this._Spouse = Spouse;
  }
  getSpouseWithFallback(): ProductModel {
    return (this._Spouse ? this._Spouse : this._Self);
  }

  get Child(): ProductModel {
    return this._Child;
  }
  set Child(Child: ProductModel) {
    this._Child = Child;
  }
  getChildWithFallback(): ProductModel {
    return (this._Child ? this._Child : this.getSpouseWithFallback());
  }

  deepCopy(): ProductFamilyModel {
    let productFamily = new ProductFamilyModel(this.FamilyName);
    if (this.Self) {
      productFamily.Self = this.Self.deepCopy();
    }
    if (this.Spouse) {
      productFamily.Spouse = this.Spouse.deepCopy();
    }
    if (this.Child) {
      productFamily.Child = this.Child.deepCopy();
    }
    return productFamily;
  }
}
