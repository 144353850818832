import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';

// import { createTranslateLoader } from './translate-loader';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent {
    constructor(translate: TranslateService, http: HttpClient) {
        // this language will be used as a fallback when a translation isn't found in the current language
        translate.setDefaultLang('en-us');

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        translate.use('en-us');
        translate.reloadLang('en-us');
    }
}
