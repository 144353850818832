import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[appGroupedCharsValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: GroupedCharsValidatorDirective, multi: true}]
})

export class GroupedCharsValidatorDirective implements Validator {

  @Input('appGroupedCharsValidator') inputValue: any;

  validate(control: AbstractControl): {[key: string]: any} {
    if (this.isEnabled() && control.value) {
      let reducedValue = this.reduceValue(control.value);
      if (!this.isFormatValid(reducedValue)) {
        return {'appFormat': true};
      }
      let formattedValue = this.formatValue(reducedValue);
      if (control.value != formattedValue) {
        control.setValue(formattedValue);
      }
    }
    return null;
  }

  isEnabled(): boolean {
    return this.inputValue !== undefined && this.inputValue !== false;
  }

  reduceValue(value: string): string {
    return value.toLowerCase().replace(/[ \.-]/g, '');
  }

  isFormatValid(value: string): boolean {
    let [total, group, chars] = this.parseInputValue();
    return !!value.match(new RegExp('^[' + chars + ']{' + total + '}$'));
  }

  formatValue(value: string): string {
    let [total, group, chars] = this.parseInputValue();
    return value.match(new RegExp('.{1,' + group + '}', 'g')).join('-');
  }

  parseInputValue(): any {
    if (!this.inputValue.toString().match(/^[0-9]+:[0-9]+:((Aa?0?)|(A?a0?)|(A?a?0))$/)) {
      throw new Error('Invalid grouped chars validator options.')
    }
    let [total, group, chars] = this.inputValue.split(':');
    chars = chars.replace('A', 'A-Z').replace('a', 'a-z').replace('0', '0-9');
    return [total, group, chars];
  }
}
