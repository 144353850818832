import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator } from "@angular/forms";
import {CreditCardService} from "../services/credit-card.service";

@Directive({
    selector: '[appCcValidate]',
    providers: [{provide: NG_VALIDATORS, useExisting: CcValidateDirective, multi: true}]
})
export class CcValidateDirective  implements Validator{

  @Input('appCcValidate') inputValue: any;

  constructor( private creditService: CreditCardService) { }

  validate(control: AbstractControl): {[key: string]: any} {
      if (this.isEnabled() && control.value) {
          if (!this.creditService.cardValidation(control.value)) {
              return {'creditFormat': 'true'};
          }
      }
      return null;
  }



  isEnabled(): boolean {
    return this.inputValue !== undefined && this.inputValue !== false;
  }
}
