export const environment = {
    production: true,
    apiGateway: {
        invokeUrl: 'https://oq3ryapcui.execute-api.us-east-2.amazonaws.com/production'
    },
    config: {
        lang: 'en-us',
        products: {
            'creditblock': 'Credit Block',
            'creditdevelopment': 'Credit Development',
            'creditrepair': 'Credit Repair',
            'creditrepairwithdevelopment': 'Credit Dev with Premium Credit Repair',
            'creditreport': 'Credit Report',
            'cybersweep': 'Cyber Sweep'
        },
        productRequiresDemographicInfo: ['creditdevelopment', 'creditrepairwithdevelopment'],
        productHasCreditBlockUpsell: ['creditdevelopment', 'creditrepairwithdevelopment', 'creditrepair','cybersweep'],
        productHasChexSystemUpsell: [],
        creditBlockUpsellNeedsSSN: ['cybersweep'],
        companyPhone: '1-800-843-0677',
        displayPartnerLogo: {
            'R6F3IXRL76SWKKC48EQ1HX9L7VGIZB6BL61L': '/assets/imgs/partners/pmcm_logo.png'
        },
        timeout:3500
    }
};
