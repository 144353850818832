import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[appPhoneValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: PhoneValidatorDirective, multi: true}]
})

export class PhoneValidatorDirective implements Validator {

  static readonly readableFormat = '(nnn) nnn-nnnn';

  @Input('appPhoneValidator') inputValue: any;

  validate(control: AbstractControl): {[key: string]: any} {
    if (this.isEnabled() && control.value) {
      let [reducedValue, country] = this.reduceValue(control.value);
      if (!this.isFormatValid(reducedValue, country)) {
        return {'appFormat': PhoneValidatorDirective.readableFormat};
      }
      if (!this.isValueValid(reducedValue, country)) {
        return {'appValue': true};
      }
      let formattedValue = this.formatValue(reducedValue, country);
      if (control.value != formattedValue) {
        control.setValue(formattedValue);
      }
    }
    return null;
  }

  isEnabled(): boolean {
    return this.inputValue !== undefined && this.inputValue !== false;
  }

  reduceValue(value: string): string[] {
    const separator = '[ \\(\\)\\.-]';
    const countryRe = new RegExp('^\\+([0-9]+)' + separator);
    let country = '';
    let reMatch = value.match(countryRe);
    if (reMatch) {
      country = '' + (+reMatch[1]);
      value = value.substring(reMatch[0].length);
    }
    const separatorRe = new RegExp(separator, 'g');
    value = value.replace(separatorRe, '').replace(/([^a-z])ext?([^a-z])/, '$1x$2');
    return [value, country];
  }

  isFormatValid(value: string, country?: string): boolean {
    if (country === undefined || country === '' || country === '1') {
      return !!value.match(/^[0-9]{10}(x[0-9]+)?$/);
    } else {
      return !!value.match(/^[0-9]+(x[0-9]+)?$/);
    }
  }

  isValueValid(value: string, country?: string): boolean {
    if (country === undefined || country === '' || country === '1') {
      return !value.match(/^[01]/) && !value.match(/^...[01]/);
    } else if (country === '0') {
      return false;
    } else {
      return !!value.match(/[1-9]/);
    }
  }

  formatValue(value: string, country?: string): string {
    if (country === undefined || country === '' || country === '1') {
      value = value.replace(/^([0-9]{3})([0-9]{3})/, '($1) $2-');
    }
    if (country !== undefined && country !== '') {
      value = '+' + country + ' ' + value;
    }
    return value.replace('x', ' x');
  }
}
