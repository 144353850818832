import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { ProductFamilyListModel } from '../models/product-family-list-model';
import { CouponModel } from '../models/coupon-model';
import { OrderModel } from '../models/order-model';
import { environment } from '../../environments/environment';

import * as jsonify from 'jsonify';
import { PersonModel } from '../models/person-model';
import { LeadModel } from '../models/lead-model';
import { EnvironmentService } from './environment.service';

@Injectable()
export class ApiGatewayService {

  private static readonly GENERAL_ERR = new Error('Connection to data failed.');

  constructor(
    private httpClient: HttpClient,
    private route: ActivatedRoute,
    private environmentService: EnvironmentService) {
  }

  getProductFamilyList(partnerKey: string): Promise<any> {
    return new Promise((resolve, reject) => {


      let args = {
        'path': '/products' + (partnerKey ? '/' + partnerKey : ''),
        'method': 'get',
        'accept': 'application/json'
      }
      this.makeGetRequest(args).then((result) => {
        let familyList = ProductFamilyListModel.fromJson(result);
        resolve(familyList);
      }).catch((err) => {

        reject(err);
        throw err;
      });
    });
  }

  getCoupon(couponKey: string): Promise<any> {
    return new Promise((resolve, reject) => {

      let args = {
        'path': '/coupons/' + couponKey,
        'method': 'get',
        'accept': 'application/json'
      }
      this.makeGetRequest(args).then((result) => {


        let coupon = CouponModel.fromJson(result);
        resolve(coupon);
      }).catch((err) => {

        reject(err);
        throw err;
      });
    });
  }

  postOrder(order: OrderModel, productFamily, productName): Promise<any> {
    return new Promise((resolve, reject) => {
      let args = {
        'path': '/orders/add',
        'method': 'post',
        'contentType': 'application/json',
        'accept': 'application/json'
      };

      const openJson = order.toOpenJson();
      openJson['ProductFamily'] = productFamily;
      openJson['ProductName'] = productName;

      const body = jsonify.stringify(openJson);
      this.makePostRequest(args, body).then((result) => {
        resolve(result);
      }).catch((err) => {
        console.log(err);
        reject(err);
        throw err;
      });
    });
  }

  private buildOptions(args: any): any {
    let headers = new HttpHeaders();
    if (args.contentType) {
      headers = headers.set('Content-Type', args.contentType);
    }
    if (!args.accept) {
      args['accept'] = 'application/json';
    }
    headers = headers.set('Accept', args.accept);
    this.route.queryParams.subscribe((params) => {
      if (params.isTest) {
        headers = headers.set('rcm-env-test', 'on');
      }
    });
    let responseType: string;
    if (args.accept == 'application/json') {
      responseType = 'json';
    } else if (args.accept.startsWith('text/')) {
      responseType = 'text';
    } else {
      responseType = 'text';
    }
    let options = {
      'headers': headers,
      'responseType': responseType,
      'observe': 'response'
    };
    return options;
  }

  private buildUrl(path: string, params?: any): string {
    if (!params) {
      params = {};
    }
    let url = environment.apiGateway.invokeUrl + path;
    let query = new Array<string>();
    for (let key of Object.keys(params)) {
      query.push(key + '=' + params[key]);
    }
    if (query.length > 0) {
      url += '?' + query.join('&');
    }
    return url;
  }

  private handleResponse(response: any, args: any) {
    return new Promise((resolve, reject) => {

      let contentType = response['headers'].get('content-type');
      let body = response['body'];
      if (contentType == 'application/json') {

        resolve(body);
      } else {


        reject(ApiGatewayService.GENERAL_ERR);
      }
    });
  }

  private makeGetRequest(args: any): Promise<any> {
    return new Promise((resolve, reject) => {


      if (!args.path) {


        reject(ApiGatewayService.GENERAL_ERR);
      }
      if (!args.accept) {
        args['accept'] = 'application/octet-stream';
      }
      let url = this.buildUrl(args.path, args.params);


      let tryIndex = 0;
      let callHttpClient = (allow504Retry: boolean) => {
        tryIndex++;

        let options = this.buildOptions(args);
        this.httpClient.get(url, options).subscribe((response) => {
          this.handleResponse(response, args).then((result) => {

            resolve(result);
          }).catch((err) => {
            reject(err);
            throw  err;
          });
        }, (httpErr) => {

          if (httpErr.status == 504 && allow504Retry) {
            // Gateway timeout. Perhaps the lambda is cold, so try one more time.
            callHttpClient(false);
          } else {
            reject(ApiGatewayService.GENERAL_ERR);
            throw httpErr;
          }
        });
      };

      callHttpClient(true);
    });
  }

  private makePostRequest(args: any, body: any): Promise<any> {
    return new Promise((resolve, reject) => {


      if (!args.path) {


        reject(ApiGatewayService.GENERAL_ERR);
      }
      if (!args.accept) {
        args['accept'] = 'application/octet-stream';
      }
      let url = this.buildUrl(args.path, args.params);


      let options = this.buildOptions(args);
      this.httpClient.post(url, body, options).subscribe((response) => {
        this.handleResponse(response, args).then((result) => {

          resolve(result);
        }).catch((err) => {

          reject(err);
          throw err;
        });
      }, (httpErr) => {

        reject(ApiGatewayService.GENERAL_ERR);
        throw httpErr;
      });
    });
  }

  getPersonAccount(personAccountId: string): Promise<any> {
      return new Promise((resolve, reject) => {


          const args = {
              'path': '/personAccounts/' + personAccountId,
              'method': 'get',
              'accept': 'application/json'
          };
          this.makeGetRequest(args).then((result) => {


              const personAccount = PersonModel.fromJson(result);
              resolve(personAccount);
          }).catch((err) => {

              reject(err);
              throw err;
          });
      });
  }

    getLead(leadId: string): Promise<any> {
        return new Promise((resolve, reject) => {


            const args = {
                'path': '/leads/' + leadId,
                'method': 'get',
                'accept': 'application/json'
            };
            this.makeGetRequest(args).then((result) => {


                const lead = LeadModel.fromJson(result);
                resolve(lead);
            }).catch((err) => {

                reject(err);
                throw err;
            });
        });
    }

  /**
   * Sends a get request to API Gateway /orders/{uuid} to check the status of the step function execution.
   * @param executionUuid
   */
  getOrderStatus(executionUuid: string): Promise<any> {
      return new Promise((resolve, reject) => {
        const args = {
          'path': '/orders/' + executionUuid,
          'method': 'get',
          'contentType': 'application/json',
          'accept': 'application/json'
        };

        this.makeGetRequest(args).then((result) => {
          resolve(result);
        }).catch((err) => {
          console.log(err);
          reject(err);
          throw err;
        });
      });
    }
}
