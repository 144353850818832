import {ErrorHandler, NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule, Title} from '@angular/platform-browser';
import {ArchwizardModule} from 'angular-archwizard';
import {ModalDialogModule} from 'ngx-modal-dialog';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {ApiGatewayService} from './services/api-gateway';
import {ProductDataService} from './services/product-data';
import {OrderDataService} from './services/order-data';
import {CreditCardService} from './services/credit-card.service';
import {WizardPageComponent} from './pages/wizard-page/wizard-page';
import {WzStepStartComponent} from './components/wz-step-start/wz-step-start';
import {WzStepAccountMgmtComponent} from './components/wz-step-account-mgmt/wz-step-account-mgmt';
import {WzStepYourInfoComponent} from './components/wz-step-your-info/wz-step-your-info';
import {WzStepFamilyInfoComponent} from './components/wz-step-family-info/wz-step-family-info';
import {WzStepBillingComponent} from './components/wz-step-billing/wz-step-billing';
import {WzStepFinishComponent} from './components/wz-step-finish/wz-step-finish';
import {WzNavBtnsRowComponent} from './components/wz-nav-btns-row/wz-nav-btns-row';
import {FormFieldComponent} from './components/form-field/form-field';
import {InvalidMessageComponent} from './components/invalid-message/invalid-message';
import {FooterComponent} from './components/footer/footer';
import {EmailValidatorDirective} from './directives/email-validator';
import {PhoneValidatorDirective} from './directives/phone-validator';
import {SsnValidatorDirective} from './directives/ssn-validator';
import {DateValidatorDirective} from './directives/date-validator';
import {GroupedCharsValidatorDirective} from './directives/grouped-chars-validator';
import {MonthYearValidatorDirective} from './directives/month-year-validator';
import {PriceHtmlPipe} from './pipes/price-html';
import {RedactPartPipe} from './pipes/redact-part';
import {TermsConditionsComponent} from './components/terms-conditions/terms-conditions.component';
import {CcValidateDirective} from './directives/cc-validate.directive';
import {GlobalErrorHandlerService} from './services/global-error-handler.service';
import {NotificationService} from './services/notification.service';
import {EnvironmentService} from './services/environment.service';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {WzStepEmploymentInfoComponent} from './components/wz-step-employment-info/wz-step-employment-info.component';
import {PersonAccountDataService} from './services/person-account';
import {LeadDataService} from './services/lead.service';
import {WzStepCreditBlockAddonComponent} from './components/wz-step-credit-block-addon/wz-step-credit-block-addon.component';
import {CreditReportImagePanelsComponent} from './components/credit-report-image-panels/credit-report-image-panels.component';
import {CurrencyValidatorDirective} from './directives/currency-validator';
import {CurrencyPipe} from '@angular/common';
import {AddressComponent} from './components/address/address.component';
import {WzStepChexsystemsAddonComponent} from './components/wz-step-chexsystems-addon/wz-step-chexsystems-addon.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {WzStepAddressComponent} from "./components/wz-step-address/wz-step-address";

export function createTranslateLoader(http: HttpClient, env: EnvironmentService, titleSvc: Title) {
    let defaultProduct = 'creditdevelopment';
    const product = env.getProductFromSubdomain();
    if (product) {
        defaultProduct = product;
    }

    setPageTitle(defaultProduct, env, titleSvc);

    const prefix = './assets/i18n/';
    const suffix = `/products/${defaultProduct}.json`;

    return new TranslateHttpLoader(http, prefix, suffix);
}

export function setPageTitle(product: string, env: EnvironmentService, titleSvc: Title) {
    const prefix = 'Regal';
    try {
        const title = env.config.products[product];
        const fullTitle = `${prefix} ${title}`;
        titleSvc.setTitle(fullTitle);
    } catch (err) {

        titleSvc.setTitle(prefix);
    }
}

@NgModule({
    declarations: [
        AppComponent,
        WizardPageComponent,
        WzStepStartComponent,
        WzStepAccountMgmtComponent,
        WzStepYourInfoComponent,
        WzStepFamilyInfoComponent,
        WzStepBillingComponent,
        WzStepAddressComponent,
        WzStepChexsystemsAddonComponent,
        WzStepFinishComponent,
        WzNavBtnsRowComponent,
        FormFieldComponent,
        InvalidMessageComponent,
        FooterComponent,
        EmailValidatorDirective,
        PhoneValidatorDirective,
        SsnValidatorDirective,
        DateValidatorDirective,
        GroupedCharsValidatorDirective,
        MonthYearValidatorDirective,
        CurrencyValidatorDirective,
        PriceHtmlPipe,
        RedactPartPipe,
        TermsConditionsComponent,
        CcValidateDirective,
        WzStepEmploymentInfoComponent,
        WzStepCreditBlockAddonComponent,
        CreditReportImagePanelsComponent,
        AddressComponent
    ],
    imports: [
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        AppRoutingModule,
        ArchwizardModule,
        ModalDialogModule.forRoot(),
        NgxSpinnerModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient, EnvironmentService, Title]
            },
            isolate: true
        }),
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        ApiGatewayService,
        EnvironmentService,
        ProductDataService,
        OrderDataService,
        CreditCardService,
        PersonAccountDataService,
        LeadDataService,
        CurrencyPipe,
        Title,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandlerService
        },
        NotificationService
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
}
