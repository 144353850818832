export class ErrorMessage {
    get text() {
        return this._text;
    }

    set text(value) {
        this._text = value;
    }

    get title() {
        return this._title;
    }

    set title(value) {
        this._title = value;
    }

    private _text: '';
    private _title: '';

    constructor(text, title){
        this._text = text;
        this._title = title;
    }

}
