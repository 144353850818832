import { PersonModel } from './person-model';
import { CreditCardModel } from './credit-card-model';
import { CouponModel } from './coupon-model';

export class OrderModel {

    constructor() {
    }

    private _AuthorizeWIRE = false;

    get AuthorizeWIRE(): boolean {
        return this._AuthorizeWIRE;
    }

    set AuthorizeWIRE(AuthorizeWIRE: boolean) {
        this._AuthorizeWIRE = AuthorizeWIRE;
    }

    private _UnderstandSmartCredit = false;

    get UnderstandSmartCredit(): boolean {
        return this._UnderstandSmartCredit;
    }

    set UnderstandSmartCredit(UnderstandSmartCredit: boolean) {
        this._UnderstandSmartCredit = UnderstandSmartCredit;
    }

    private _UnderstandSupportedBrowsers = false;

    get UnderstandSupportedBrowsers() {
        return this._UnderstandSupportedBrowsers;
    }

    set UnderstandSupportedBrowsers(value) {
        this._UnderstandSupportedBrowsers = value;
    }

    private _LeadSelf = new PersonModel();

    get LeadSelf(): PersonModel {
        return this._LeadSelf;
    }

    set LeadSelf(LeadSelf: PersonModel) {
        this._LeadSelf = LeadSelf;
    }

    private _LeadManager: PersonModel = null;

    get LeadManager(): PersonModel {
        return this._LeadManager;
    }

    set LeadManager(LeadManager: PersonModel) {
        this._LeadManager = LeadManager;
    }

    private _LeadSpouse: PersonModel = null;

    get LeadSpouse(): PersonModel {
        return this._LeadSpouse;
    }

    set LeadSpouse(LeadSpouse: PersonModel) {
        this._LeadSpouse = LeadSpouse;
    }

    private _LeadChildren: PersonModel[] = [];

    get LeadChildren(): Array<PersonModel> {
        return this._LeadChildren;
    }

    set LeadChildren(LeadChildren: PersonModel[]) {
        this._LeadChildren = LeadChildren;
    }

    private _CreditCard = new CreditCardModel();

    get CreditCard(): CreditCardModel {
        return this._CreditCard;
    }

    set CreditCard(CreditCard: CreditCardModel) {
        this._CreditCard = CreditCard;
    }

    private _Coupon: CouponModel = null;

    get Coupon(): CouponModel {
        return this._Coupon;
    }

    set Coupon(Coupon: CouponModel) {
        this._Coupon = Coupon;
    }

    private _PartnerKey = '';

    get PartnerKey(): string {
        return this._PartnerKey;
    }

    set PartnerKey(key: string) {
        this._PartnerKey = key;
    }

    get hasUpsellSelf(): boolean {
        return this._hasUpsellSelf;
    }
    
    set hasUpsellSelf(value: boolean) {
        this._hasUpsellSelf = value;
    }
    
    get hasUpsellSpouse(): boolean {
        return this._hasUpsellSpouse;
    }
    
    set hasUpsellSpouse(value: boolean) {
        this._hasUpsellSpouse = value;
    }
    
    private _hasUpsellSelf = false;
    private _hasUpsellSpouse = false;
    
    get upsellProductNameSelf(): string {
        return this._upsellProductNameSelf;
    }
    
    set upsellProductNameSelf(value: string) {
        this._upsellProductNameSelf = value;
    }
    
    get upsellProductNameSpouse(): string {
        return this._upsellProductNameSpouse;
    }
    
    set upsellProductNameSpouse(value: string) {
        this._upsellProductNameSpouse = value;
    }
    
    private _upsellProductNameSelf = '';
    private _upsellProductNameSpouse = '';

    private _SelfAccountId = '';

    get SelfAccountId(): string {
      return this._SelfAccountId;
    }

    set SelfAccountId(SelfAccountId: string) {
      this._SelfAccountId = SelfAccountId;
    }

    private _SelfContactId = '';
    get SelfContactId(): string{
        return this._SelfContactId;
    }
    set SelfContactId(value: string){
        this._SelfContactId = value;
    }

    private _LeadId = '';

    get LeadId(): string {
      return this._LeadId;
    }

    set LeadId(LeadId: string) {
      this._LeadId = LeadId;
    }

    addChild(Child: PersonModel) {
        this._LeadChildren.push(Child);
    }

    isAccountManaged(): boolean {
        if (this._LeadManager != null) {
            return true;
        } else {
            return false;
        }
    }

    deepCopy(): OrderModel {
        const order = new OrderModel();
        order.AuthorizeWIRE = this.AuthorizeWIRE;
        order.UnderstandSmartCredit = this.UnderstandSmartCredit;
        order.UnderstandSupportedBrowsers = this.UnderstandSupportedBrowsers;
        order.hasUpsellSelf = this.hasUpsellSelf;
        order.hasUpsellSpouse = this.hasUpsellSpouse;
        order.upsellProductNameSelf = this.upsellProductNameSelf;
        order.upsellProductNameSpouse = this.upsellProductNameSpouse;
        order.LeadSelf = this.LeadSelf.deepCopy();
        if (this.LeadManager) {
            order.LeadManager = this.LeadManager.deepCopy();
        }
        if (this.LeadSpouse) {
            order.LeadSpouse = this.LeadSpouse.deepCopy();
        }
        for (const child of this.LeadChildren) {
            order.addChild(child.deepCopy());
        }
        order.CreditCard = this.CreditCard.deepCopy();
        order.Coupon = this.Coupon.deepCopy();
        return order;
    }

    toOpenJson(): any {
        const json = {
            'LeadSelf': this.LeadSelf.toOpenJson(),
            'LeadManager': (this.LeadManager ? this.LeadManager.toOpenJson() : null),
            'LeadSpouse': (this.LeadSpouse ? this.LeadSpouse.toOpenJson() : null),
            'LeadChildren': [],
            'CreditCard': this.CreditCard.toOpenJson(),
            'CouponKey': (this.Coupon ? this.Coupon.CouponKey : ''),
            'PartnerKey': this.PartnerKey,
            'SelfAccountId': this.SelfAccountId,
            'LeadId': this.LeadId,
            'HasUpsellSelf': this.hasUpsellSelf,
            'HasUpsellSpouse': this.hasUpsellSpouse,
            'UpsellProductNameSelf': this.upsellProductNameSelf,
            'UpsellProductNameSpouse': this.upsellProductNameSpouse,
            'ProductFamily': '',
        };
        for (const child of this.LeadChildren) {
            json['LeadChildren'].push(child.toOpenJson());
        }
        return json;
    }

    toRedactedJson(): any {
        const json = {
            'LeadSelf': this.LeadSelf.toRedactedJson(),
            'LeadManager': (this.LeadManager ? this.LeadManager.toRedactedJson() : null),
            'LeadSpouse': (this.LeadSpouse ? this.LeadSpouse.toRedactedJson() : null),
            'LeadChildren': [],
            'CreditCard': this.CreditCard.toRedactedJson(),
            'CouponKey': (this.Coupon ? this.Coupon.CouponKey : ''),
            'PartnerKey': this.PartnerKey,
            'SelfAccountId': this.SelfAccountId,
            'LeadId': this.LeadId,
            'HasUpsellSelf': this.hasUpsellSelf,
            'HasUpsellSpouse': this.hasUpsellSpouse,
            'UpsellProductNameSelf': this.upsellProductNameSelf,
            'UpsellProductNameSpouse': this.upsellProductNameSpouse,
            'ProductFamily': ''
        };
        for (const child of this.LeadChildren) {
            json['LeadChildren'].push(child.toRedactedJson());
        }
        return json;
    }
}
