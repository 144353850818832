import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { OrderDataService } from '../../services/order-data';
import { PhoneValidatorDirective } from '../../directives/phone-validator';
import { SsnValidatorDirective } from '../../directives/ssn-validator';
import { DateValidatorDirective } from '../../directives/date-validator';
import { PersonModel } from '../../models/person-model';
import { EnvironmentService } from '../../services/environment.service';
import { ProductDataService } from '../../services/product-data';
import { ProductModel } from '../../models/product-model';
import { FormFieldComponent } from '../form-field/form-field';
import { ApiGatewayService } from '../../services/api-gateway';
import { OrderLineModel } from '../../models/order-line-model';
import { OrderModel } from '../../models/order-model';
import { isNullOrUndefined } from 'util';
import { ProductFamilyListModel, ProductFamilyType } from '../../models/product-family-list-model';
import { ProductFamilyModel } from '../../models/product-family-model';
import { from } from 'rxjs/observable/from';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-wz-step-chexsystems-addon',
    templateUrl: './wz-step-chexsystems-addon.component.html',
    styleUrls: ['./wz-step-chexsystems-addon.component.scss']
})
export class WzStepChexsystemsAddonComponent implements OnInit {
    @ViewChildren(FormFieldComponent) formFields: QueryList<FormFieldComponent>;
    @Output() clear = new EventEmitter();
    data = 'value';
    peopleList = new Array();
    readonly PRIMARY = 'primary';
    readonly SPOUSE = 'spouse';
    readonly CHILD = 'child';
    readonly PRIMARY_HEADER_LABEL = "Primary: ";
    readonly SPOUSE_HEADER_LABEL = "Spouse: ";
    readonly CHILD_HEADER_LABEL = "Adult Child: ";
    readonly PRIMARY_FIELD_NAME = 'ChexsystemsStatus-primary';
    readonly SPOUSE_FIELD_NAME = 'ChexsystemsStatus-spouse';
    readonly CHILD_FIELD_NAME = 'ChexsystemsStatus-child';
    readonly CHILD_FIELD_NAME_REPLACE = "ChexsystemsStatus-child-";


    constructor(protected envSvc: EnvironmentService,
                protected orderData: OrderDataService,
                protected productData: ProductDataService,
                protected apiGateway: ApiGatewayService,
                protected changeRef: ChangeDetectorRef) {
    }
    isChild(personObject){
        return personObject.type == this.CHILD;
    }
    formatHeader(personObject){
        let output = "";
        switch(personObject.type){
            case this.PRIMARY:
                output = this.PRIMARY_HEADER_LABEL;
                break;
            case this.SPOUSE:
                output = this.SPOUSE_HEADER_LABEL;
                break;
            case this.CHILD:
                output = this.CHILD_HEADER_LABEL;
                break;
            default:
                break;
        }
        return output;
    }
    formatName(personObject){
        return personObject.person.FirstName + ' ' + personObject.person.LastName;
    }

    addPeople(orderData){
        this.peopleList = new Array();
        if(orderData) {
            this.AddPrimary(orderData);
            this.AddSpouse(orderData);
            this.AddChildren(orderData);
        }
    }

    private AddPrimary(orderData) {
        this.peopleList.push(this.createObject(orderData.LeadSelf,this.PRIMARY, -1));
    }

    private AddSpouse(orderData) {
        if (orderData.LeadSpouse) {
            this.peopleList.push(
                this.createObject(orderData.LeadSpouse,this.SPOUSE, -1));
        }
    }

    /***
     * @param person
     * @param type
     * @param childIndex - used to identify multiple adult children only
     */
    private createObject(person, type, childIndex) {
        function cleanID(person, childIndex){
            if(childIndex === -1) {
                return person.firstName;
            }else{
                return person.firstName + '-' + childIndex;
            }
        }

        return {
            'person': person,
            'type': type,
            'data': -1,
            'id': cleanID(person, childIndex),
            'childIndex': childIndex
        };
    }
    private AddChildren(orderData) {
        if (orderData.LeadChildren) {
            for (var i = 0; i < orderData.LeadChildren.length; ++i) {
                if (!orderData.LeadChildren[i].isUnder18()) {
                    this.peopleList.push( this.createObject(orderData.LeadChildren[i],this.CHILD, i));
                }
            }
        }
    }

    getName(personObject){
        if(personObject.childIndex === -1) {
            return personObject.type;
        }else{
            return personObject.type + "-" + personObject.childIndex;
        }
    }

    onAcceptChexSystemChange(formField: FormFieldComponent) {
        if (formField && formField.name && formField.name === this.PRIMARY_FIELD_NAME) {
            let primary = this.peopleList.find(x => x.type === this.PRIMARY);
            this.setPersonData(formField.value, primary);
        } else if (formField && formField.name &&  formField.name === this.SPOUSE_FIELD_NAME) {
            let spouse = this.peopleList.find(x=> x.type === this.SPOUSE);
            this.setPersonData(formField.value, spouse);
        }else if(formField && formField.name &&  formField.name.includes(this.CHILD_FIELD_NAME)){
            let index = formField.name.replace(this.CHILD_FIELD_NAME_REPLACE,'');
            let child = this.peopleList.find(x=>{return x.type === this.CHILD && x.childIndex == index});
            this.setPersonData(formField.value, child);
        }
    }

    private setPersonData(result, personData) {
        if (personData) {
            personData.person.IsChexSystem = result;
        } else {
            console.error('invalid ChexsystemsStatus value ' + result);
        }
    }

    ngOnInit() {

    }



    onClear() {
        this.clear.emit(this.formFields);
    }

    onStepEnter() {
        this.addPeople(this.orderData.order);
    }

    onStepExit() {

    }
}
