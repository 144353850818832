import { RedactPartPipe } from '../pipes/redact-part';
import * as moment from 'moment';
import {AddressModel} from "./address-model";

export class LeadModel {


    private _FirstName = '';
    private _MiddleName = '';
    private _LastName = '';
    private _Suffix = '';
    private _Email = '';
    private _Title = '';
    private _MobilePhone = '';
    private _LandPhone = '';
    private _Ssn = '';
    private _Birthdate = '';

    constructor() {
    }

    static fromJson(json: any): LeadModel {
        let lead = new LeadModel();
        lead.setFromJson(json);
        return lead;
    }

    setFromJson(json: any) {
        if (json.FirstName) {
          this.FirstName = json.FirstName;
        }
        if (json.MiddleName) {
          this.MiddleName = json.MiddleName;
        }
        if (json.LastName !== null) {
          this.LastName = json.LastName;
        }
        if (json.Suffix !== null) {
          this.Suffix = json.Suffix;
        }
        if (json.Title !== null) {
            this.Title = json.Suffix;
        }
        if (json.Email !== null) {
            this.Email = json.Email;
        }
        if (json.MobilePhone !== null) {
            this.MobilePhone = json.MobilePhone;
        }
        if (json.LandPhone !== null) {
            this.LandPhone = json.LandPhone;
        }
        if (json.Ssn !== null) {
            this.Ssn = json.Ssn;
        }
        if (json.Birthdate !== null) {
            this.Birthdate = json.Birthdate;
        }
    }

    get FirstName(): string {
      return this._FirstName;
    }

    set FirstName(FirstName: string) {
      this._FirstName = FirstName;
    }

    get MiddleName(): string {
      return this._MiddleName;
    }

    set MiddleName(MiddleName: string) {
      this._MiddleName = MiddleName;
    }

    get LastName(): string {
      return this._LastName;
    }

    set LastName(LastName: string) {
      this._LastName = LastName;
    }

    get Suffix(): string {
      return this._Suffix;
    }

    set Suffix(Suffix: string) {
      this._Suffix = Suffix;
    }

    get Title(): string {
        return this._Title;
    }

    set Title(Title: string) {
        this._Title = Title;
    }

    get Email(): string {
        return this._Email;
    }

    set Email(Email: string) {
        this._Email = Email;
    }

    get MobilePhone(): string {
        return this._MobilePhone;
    }

    set MobilePhone(MobilePhone: string) {
        this._MobilePhone = MobilePhone;
    }

    get LandPhone(): string {
        return this._LandPhone;
    }

    set LandPhone(LandPhone: string) {
        this._LandPhone = LandPhone;
    }

    get Ssn(): string {
        return this._Ssn;
    }

    set Ssn(Ssn: string) {
        this._Ssn = Ssn;
    }

    get Birthdate(): string {
        return this._Birthdate;
    }

    set Birthdate(Birthdate: string) {
        this._Birthdate = Birthdate;
    }


    deepCopy(): LeadModel {
        const person = new LeadModel();
        person.FirstName = this.FirstName;
        person.MiddleName = this.MiddleName;
        person.LastName = this.LastName;
        person.Suffix = this.Suffix;
        person.Title = this.Title;
        person.Email = this.Email;
        person.MobilePhone = this.MobilePhone;
        person.LandPhone = this.LandPhone;
        person.Ssn = this.Ssn;
        person.Birthdate = this.Birthdate;
        return person;
    }


    toOpenJson(): any {
        let json = {
            'FirstName': this.FirstName,
            'MiddleName': this.MiddleName,
            'LastName': this.LastName,
            'Suffix': this.Suffix,
            'Title': this.Title,
            'Email': this.Email,
            'MobilePhone': this.MobilePhone,
            'LandPhone': this.LandPhone,
            'Ssn': this.Ssn,
            'Birthdate': this.Birthdate,
        };
        return json;
    }

    toRedactedJson(): any {
        let redactPart = new RedactPartPipe();
        let json = {
            'FirstName': redactPart.transform(this.FirstName, 1),
            'MiddleName': redactPart.transform(this.MiddleName, 1),
            'LastName': redactPart.transform(this.LastName, 1),
            'Suffix': this.Suffix,
            'Title': this.Title,
            'Email': redactPart.transform(this.Email, 1),
            'MobilePhone': redactPart.transform(this.MobilePhone, 5),
            'LandPhone': redactPart.transform(this.LandPhone, 5),
            'Ssn': redactPart.transform(this.Ssn, -2),
            'Birthdate': redactPart.transform(this.Birthdate, -4),
        };
        return json;
    }
}
