import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
    ViewContainerRef
} from '@angular/core';
import {ModalDialogService, SimpleModalComponent} from 'ngx-modal-dialog';
import {FormFieldComponent} from '../../components/form-field/form-field';
import {ApiGatewayService} from '../../services/api-gateway';
import {ProductDataService} from '../../services/product-data';
import {OrderDataService} from '../../services/order-data';
import {ProductFamilyPicklistValues, ProductModel} from '../../models/product-model';
import {OrderLineModel} from '../../models/order-line-model';
import {ProductFamilyType} from '../../models/product-family-list-model';
import {EnvironmentService} from '../../services/environment.service';
import {ProductFamilyModel} from '../../models/product-family-model';
import {PersonModel} from '../../models/person-model';

@Component({
    selector: 'app-wz-step-billing',
    templateUrl: './wz-step-billing.html',
    styleUrls: ['./wz-step-billing.scss']
})

export class WzStepBillingComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChildren(FormFieldComponent) formFields: QueryList<FormFieldComponent>;

    @ViewChild('discountCode') discountCodeFormField: FormFieldComponent;

    @Input()
    @Output() onSendOrder: EventEmitter<any> = new EventEmitter();
    @Output() clear = new EventEmitter();
    private readonly CREDIT_BLOCK = 'Credit Block';
    private readonly CREDIT_BLOCK_UPSELL = 'Credit Block Add-On';
    private readonly CREDIT_REPORT = 'Credit Report';
    private readonly CYBER_SWEEP = 'Cyber Sweep';
    private readonly CHECK_PRICE = 'YES'
    private readonly TRUE = 'true'
    couponKey = '';
    partnerKey = null;
    allOrderLines = new Array<OrderLineModel>();
    discountOrderLine: OrderLineModel;
    totalOrderLine: OrderLineModel;
    showZeroPrice = false;
    showName = 'Credit Report';
    showPrice = '0.00';
    feeAssessmentText = 'annually';
    isCreditBlockPresent = false;
    hasFreeCreditReport = '';
    readonly CHEXSYSTEMS = 'ChexSystems <br><span class="small">(Annually)</span>';
    readonly CHEXSYSTEM_PRICE = 200.00;

    constructor(
        public modal: ModalDialogService,
        public viewRef: ViewContainerRef,
        public apiGateway: ApiGatewayService,
        public productData: ProductDataService,
        public orderData: OrderDataService,
        public envSvc: EnvironmentService,
    ) {
    }

    ngOnInit() {

    }

    allOrderLinesUnique(array) {
        const a = array.concat();
        for (let i = 0; i < a.length; ++i) {
            for (let j = i + 1; j < a.length; ++j) {
                if (a[i] === a[j]) {
                    a.splice(j--, 1);
                }
            }
        }

        return a;
    }

    ngOnChanges() {
    }

    ngAfterViewInit() {
    }

    sendOrder() {
        this.onSendOrder.emit();
    }

    applyDiscount() {
        let text = '';
        const ngModel = this.discountCodeFormField.ngModel;
        if (!ngModel.value) {
            text = 'There is no discount code to apply.';
        } else if (ngModel.errors) {
            text = 'Invalid discount code format.';
        }
        if (text) {
            this.showErrorModal('Discount Error', text);
            return;
        }

        this.apiGateway.getCoupon(ngModel.value).then((coupon) => {
            this.orderData.order.Coupon = coupon;
            this.updateDiscountOrderLine();
        }).catch((err) => {
            this.orderData.order.Coupon = null;
            this.updateDiscountOrderLine();
            text = 'Invalid discount code value.';
            this.showErrorModal('Discount Error', text); // ToDo: Migrate this to Error Notification
        });
    }

    updateDiscountOrderLine() {
        this.totalOrderLine.Price -= this.discountOrderLine.Price;
        if (this.orderData.order.Coupon) {
            this.discountOrderLine.Price = -this.orderData.order.Coupon.DiscountPrice;
            this.totalOrderLine.Price += this.discountOrderLine.Price;
        } else {
            this.discountOrderLine.Price = 0;
        }
        this.totalOrderLine.Price = Math.max(this.totalOrderLine.Price, 0); // total due is not allowed to be negative
    }

    onClear() {
        this.clear.emit(this.formFields);
    }

    ifFree(checkPrice, checkName) {
        if (checkPrice === this.CHECK_PRICE && checkName === this.CREDIT_REPORT) {
            this.showZeroPrice = true;
        }
    }

    onStepEnter() {
        this.hasFreeCreditReport = this.productData.familyList.HasFreeCreditReport;
        const hasPremiumCreditRepairPricing: boolean = this.productData.premiumCreditRepairFamilies ? true : false;
        const familyFromSubdomain: string = this.envSvc.getProductFamilyFromSubdomain();
        const addOnFamily = 'Credit Block Add-On';

        let selfProducts: ProductFamilyModel[];
        selfProducts = hasPremiumCreditRepairPricing ?
            this.productData.premiumCreditRepairFamilies : this.productData.findProductFamilyList(familyFromSubdomain);
        const selfProductFamily = this.productData.findFamilyTypeFamily(selfProducts, ProductFamilyType.Self);
        const familyName = selfProductFamily.FamilyName;

        this.ifFree(this.hasFreeCreditReport, familyName);

        const lineItemText = this.setOrderLinesSecondaryText(familyName);

        const coupon = this.orderData.order.Coupon;
        this.couponKey = (coupon ? coupon.CouponKey : '');
        const discountPrice = (coupon ? -coupon.DiscountPrice : 0);
        this.allOrderLines = [];
        this.discountOrderLine = new OrderLineModel('', 'Discount', discountPrice, true);
        this.totalOrderLine = new OrderLineModel('', 'Total Due', 0);
        let selfAdditionalProduct: ProductModel;
        let addlProducts: ProductFamilyModel[];
        const monthlyOrderLine = new OrderLineModel('', 'Total Due Monthly' + '<br><span class="small">(Due After The First 30 Days)</span>', 0);
        const creditRepairMonthlyProducts = this.productData.findProductFamilyList('Credit Repair Monthly');

        addlProducts = this.productData.findProductFamilyList(addOnFamily);
        addlProducts = addlProducts.filter(this.productData.filterCreditBlockSpecialPackageFromProductList);

        if (this.orderData.addOnOrder && this.orderData.addOnOrder.hasUpsellSelf) {
            this.isCreditBlockPresent = true;
            this.orderData.order.LeadSelf = PersonModel.setProductStageFlagsOnPerson(this.orderData.order.LeadSelf, addOnFamily);
            const selfAddlProductFamily = this.productData.findFamilyTypeFamily(addlProducts, ProductFamilyType.Self);
            selfAdditionalProduct = selfAddlProductFamily.Self;
            selfAddlProductFamily.Self.ProductName = selfAdditionalProduct.ProductName;
            this.orderData.order.upsellProductNameSelf = selfAdditionalProduct.ProductName;
            this.totalOrderLine.Price += selfAdditionalProduct.BasePrice;
        }

        const description = this.orderData.order.LeadSelf.getFullName();
        const product = selfProductFamily.Self;
        this.orderData.order.LeadSelf.ProductName = product.ProductName;
        this.orderData.order.LeadSelf = PersonModel.setProductStageFlagsOnPerson(this.orderData.order.LeadSelf, familyName);
        this.totalOrderLine.Price += product.BasePrice;

        this.allOrderLines.push(new OrderLineModel(description, familyName + ' <br><span class="small">' + lineItemText + '</span>', product.BasePrice));

        if (this.showAddlPriceOrderLine()) {
            this.totalOrderLine.FamilyName = 'Total Due Today';
            const selfMonthlyPrice = this.productData.findMonthlyPrice(creditRepairMonthlyProducts, ProductFamilyType.Self, this.orderData.order.PartnerKey);
            monthlyOrderLine.Price += selfMonthlyPrice;
            const primaryIndicator = this.orderData.order.LeadSelf.getFullName();
            this.allOrderLines.push(new OrderLineModel(primaryIndicator, familyName + ' <br><span class="small">(Ongoing Monthly Fee)</span>', selfMonthlyPrice, true));
        }
        this.SpouseOrderLines(hasPremiumCreditRepairPricing, familyFromSubdomain, familyName, addOnFamily, addlProducts,
            lineItemText, creditRepairMonthlyProducts, monthlyOrderLine);

        this.ChildOrderLines(hasPremiumCreditRepairPricing, familyFromSubdomain, selfProductFamily, familyName, lineItemText, creditRepairMonthlyProducts, monthlyOrderLine);

        this.ChexSystemOrderLines();

        this.allOrderLines = this.allOrderLines
            .concat(this.orderData.additionalOrderLines);

        this.allOrderLines.push(this.discountOrderLine);
        if (this.showAddlPriceOrderLine()) {
            this.allOrderLines.push(monthlyOrderLine);
        }
        this.allOrderLines.push(this.totalOrderLine);
    }

    showAddlPriceOrderLine() {
        const productFamily: string = this.envSvc.getProductFromSubdomain();

        switch (productFamily) {
            case 'creditrepairwithdevelopment':
            case 'creditrepair':
                return true;
            default:
                return false;
        }
    }

    private ChexSystemOrderLines() {
        if (this.orderData.order.LeadSelf.IsChexSystem === this.TRUE) {
            this.orderData.order.LeadSelf = PersonModel.setProductStageFlagsOnPerson(this.orderData.order.LeadSelf, ProductFamilyPicklistValues.ChexSystem);
            this.CheckSystemOrderLineAdd(this.orderData.order.LeadSelf.getFullName());
        }
        if (this.orderData.order.LeadSpouse && this.orderData.order.LeadSpouse.IsChexSystem === this.TRUE) {
            this.orderData.order.LeadSpouse = PersonModel.setProductStageFlagsOnPerson(this.orderData.order.LeadSpouse, ProductFamilyPicklistValues.ChexSystem);
            this.CheckSystemOrderLineAdd(this.orderData.order.LeadSpouse.getFullName());
        }
        for (let i = 0; i < this.orderData.order.LeadChildren.length; ++i) {
            if (this.orderData.order.LeadChildren[i].IsChexSystem === this.TRUE) {
                this.orderData.order.LeadChildren[i] = PersonModel.setProductStageFlagsOnPerson(this.orderData.order.LeadChildren[i], ProductFamilyPicklistValues.ChexSystem);
                this.CheckSystemOrderLineAdd(this.orderData.order.LeadChildren[i].getFullName());
            }
        }
    }

    private CheckSystemOrderLineAdd(personName) {
        this.allOrderLines.push(new OrderLineModel(personName, this.CHEXSYSTEMS, this.CHEXSYSTEM_PRICE));
        this.totalOrderLine.Price += this.CHEXSYSTEM_PRICE;
    }

    private ChildOrderLines(hasPremiumCreditRepairPricing: boolean, familyFromSubdomain: string, selfProductFamily: ProductFamilyModel, familyName: string, lineItemText: string, creditRepairMonthlyProducts: ProductFamilyModel[], monthlyOrderLine: OrderLineModel) {
        // Child
        for (const child of this.orderData.order.LeadChildren) {
            let orderDataChild = this.orderData.order.LeadChildren[this.orderData.order.LeadChildren.indexOf(child)];
            const isUnder18 = child.isUnder18();
            let childProducts: ProductFamilyModel[];
            childProducts = hasPremiumCreditRepairPricing ?
                this.productData.premiumCreditRepairFamilies : this.productData.findProductFamilyList(familyFromSubdomain);
            const childProductFamily = this.productData.findFamilyTypeFamily(childProducts, ProductFamilyType.Child);
            const childProduct = (isUnder18 ? childProductFamily.Child : selfProductFamily.Self); // price is same as self is child under 18
            orderDataChild.ProductName = childProduct.ProductName;
            orderDataChild = PersonModel.setProductStageFlagsOnPerson(orderDataChild, familyName);
            let childDescription = child.getFullName();
            let childIndicator = '';
            childDescription += childIndicator;
            this.totalOrderLine.Price += childProduct.BasePrice;
            this.allOrderLines.push(new OrderLineModel(childDescription, familyName + ' <br><span class="small">' + lineItemText + '</span>', childProduct.BasePrice, true));
            if (this.showAddlPriceOrderLine()) { // child monthly price is currently not used or displayed
                this.totalOrderLine.FamilyName = 'Total Due Today';
                const childMonthlyPrice = this.productData.findMonthlyPrice(creditRepairMonthlyProducts, ProductFamilyType.Child, this.orderData.order.PartnerKey);
                monthlyOrderLine.Price += childMonthlyPrice;
                childIndicator = child.getFullName();
                this.allOrderLines.push(new OrderLineModel(childIndicator, familyName + ' <br><span class="small">(Ongoing Monthly Fee)</span>', childMonthlyPrice, true));
            }
        }
    }

    private SpouseOrderLines(hasPremiumCreditRepairPricing: boolean, familyFromSubdomain: string, familyName: string, addOnFamily: string, addlProducts: ProductFamilyModel[], lineItemText: string, creditRepairMonthlyProducts: ProductFamilyModel[], monthlyOrderLine: OrderLineModel) {
        // Spouse
        if (this.orderData.order.LeadSpouse) {
            let spouseProducts: ProductFamilyModel[];
            let spouseAdditionalProduct: ProductModel;
            spouseProducts = hasPremiumCreditRepairPricing ?
                this.productData.premiumCreditRepairFamilies : this.productData.findProductFamilyList(familyFromSubdomain);
            const spouseProductFamily = this.productData.findFamilyTypeFamily(spouseProducts, ProductFamilyType.Spouse);

            let spouseDescription = this.orderData.order.LeadSpouse.getFullName();
            let spouseIndicator = '';
            spouseDescription += spouseIndicator;
            const spouseProduct = spouseProductFamily.Spouse;
            this.orderData.order.LeadSpouse.ProductName = spouseProduct.ProductName;
            this.orderData.order.LeadSpouse = PersonModel.setProductStageFlagsOnPerson(this.orderData.order.LeadSpouse, familyName);
            this.totalOrderLine.Price += spouseProduct.BasePrice;

            if (this.orderData.addOnOrder && this.orderData.addOnOrder.hasUpsellSpouse) {
                this.isCreditBlockPresent = true;
                this.orderData.order.LeadSpouse = PersonModel.setProductStageFlagsOnPerson(this.orderData.order.LeadSpouse, addOnFamily);
                const spouseAddlProductFamily = this.productData.findFamilyTypeFamily(addlProducts, ProductFamilyType.Spouse);
                spouseAdditionalProduct = spouseAddlProductFamily.Spouse;
                this.orderData.order.upsellProductNameSpouse = spouseAdditionalProduct.ProductName;
                this.totalOrderLine.Price += spouseAdditionalProduct.BasePrice;
            }

            this.allOrderLines.push(new OrderLineModel(spouseDescription, familyName + ' <br><span class="small">' + lineItemText + '</span>', spouseProduct.BasePrice, true));
            if (this.showAddlPriceOrderLine()) {
                this.totalOrderLine.FamilyName = 'Total Due Today';
                const spouseMonthlyPrice = this.productData.findMonthlyPrice(creditRepairMonthlyProducts, ProductFamilyType.Spouse, this.orderData.order.PartnerKey);
                monthlyOrderLine.Price += spouseMonthlyPrice;
                spouseIndicator = this.orderData.order.LeadSpouse.getFullName();
                this.allOrderLines.push(new OrderLineModel(spouseIndicator, familyName + ' <br><span class="small">(Ongoing Monthly Fee)</span>', spouseMonthlyPrice, true));
            }
        }
    }

    private showErrorModal(title: string, text: string) {
        this.modal.openDialog(this.viewRef, {
            childComponent: SimpleModalComponent,
            settings: {
                contentClass: 'modal-content error'
            },
            title: title,
            data: {
                text: text
            },
            actionButtons: [{
                text: 'OK',
                buttonClass: 'btn btn-light'
            }]
        });
    }

    private isClassDisplayNone(line: any) {
        if (!line.description && !line.Price) {
            if (line.Price === 0) {
                return false;
            }
            return true;
        }
        return false;
    }

    private setOrderLinesSecondaryText(familyName: string) {
        let lineItemText = '(Audit and Consultation Fee)';
        switch (familyName) {
            case this.CREDIT_BLOCK:
                this.isCreditBlockPresent = true;
                lineItemText = '(Annually)';
                break;
            case this.CREDIT_BLOCK_UPSELL:
                this.isCreditBlockPresent = true;
                lineItemText = '(Annually)';
                break;
            case this.CREDIT_REPORT:
                lineItemText = '(One-Time)';
                break;
            case this.CYBER_SWEEP:
                lineItemText = '(Annually)';
                break;
            default:
                lineItemText = '(Audit and Consultation Fee)';
                break;
        }
        return lineItemText;
    }
}
