import {
  AfterContentChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.html',
  styleUrls: ['./form-field.scss']
})

export class FormFieldComponent implements OnInit {

  @Input() type: string;
  @Input() name: string;
  @Input() label: string;
  @Input() divClass = '';
  @Input() value: any;
  @Input() options: any;
  @Input() required: any;
  @Input() disabled: any = null;
  @Input() pattern: any;
  @Input() dateValidator: any;
  @Input() emailValidator: any;
  @Input() phoneValidator: any;
  @Input() ssnValidator: any;
  @Input() groupedCharsValidator: any;
  @Input() ccValidate: any;
  @Input() monthYearValidator: any;
  @Input() currencyValidator: any;
  @Input() dollarPrefixed: any;

  @Input() placeholder = '';
  @Input() minLength = 0;
  @Input() maxLength = 765;
  @Output() change = new EventEmitter();

  @Input() data: any;
  @Output() dataChange = new EventEmitter();

  @ViewChild('ngModel') ngModel: NgModel;
  @ViewChild('ssnInput') ssnInput: ElementRef;

  shownData = '';

  static maskSsn(ssn: string): string {
    if (ssn) {
      return ssn.replace(/[0-9]/g, '*');
    } else {
      return '';
    }
  }

  constructor() {
  }

  ngOnInit() {
  }

  ngAfterContentChecked() {
    if (this.ssnValidator) {
      // initialize shownData when field has been auto-populated
      if (this.data && !this.shownData) {
        this.shownData = FormFieldComponent.maskSsn(this.data);
      }
    }
  }

  onChange() {
    this.change.emit(this);
    this.dataChange.emit(this.data);
  }

  onFocus() {
    if (this.ssnValidator) {
      this.shownData = this.data;
    }
  }

  onBlur() {
    if (this.ssnValidator) {
      this.data = this.shownData;
      this.shownData = FormFieldComponent.maskSsn(this.data);
    }
    this.dataChange.emit(this.data);
  }
  
  /**
   * Prevents anything but numbers and dashes from being entered on keypress
   * @param $event The received keyboard event
   */
  onKeypress($event) {
    console.log('onkeypress');
    const allowedKeys = '01234567890-';
    const key = $event.key;
    if (allowedKeys.indexOf(key) === -1) {
      return false;
    }
  }
  
  /**
   * Disable the paste operation
   * @param $event The received keyboard event
   */
  onPaste($event) {
    $event.preventDefault();
  }

  getOptionText(option): string {
    return (option.text ? option.text : (option.value ? option.value : option));
  }

  getOptionValue(option): string {
    return (option.value ? option.value : option);
  }

  getSelected(option, current) {
    return option === current ? 'Selected' : '';
  }
}
