import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MovingDirection } from 'angular-archwizard';
import { ModalDialogService, SimpleModalComponent } from 'ngx-modal-dialog';
import { ApiGatewayService } from '../../services/api-gateway';
import { OrderDataService } from '../../services/order-data';
import { NotificationService } from '../../services/notification.service';
import { ProductDataService } from '../../services/product-data';
import { PersonAccountDataService } from '../../services/person-account';
import { EnvironmentService } from '../../services/environment.service';
import { LeadDataService } from '../../services/lead.service';
import { ProductFamilyType } from '../../models/product-family-list-model';

@Component({
    selector: 'app-wizard-page',
    templateUrl: './wizard-page.html',
    styleUrls: ['./wizard-page.scss']
})

export class WizardPageComponent implements OnInit {
    queryParams: QueryParamObject;

    private readonly CYBERSWEEP = 'cybersweep'
    public navBarLocation = '';
    private personInformationDefault = 'Your Information';
    private personInformationAccountManaged = 'Client Information';
    private connectionError = false;
    private _personInformation = this.personInformationDefault;
    private partnerKey: string = null;
    private logoPath = '/assets/imgs/regal_logo_white_min.png';
    public logoStyleType: 'lock' | 'panel' = 'lock';

    private readonly RUNNING = 'RUNNING'; // SFN execution status 'RUNNING'
    private readonly SUCCEEDED = 'SUCCEEDED'; // SFN execution status 'SUCCEEDED'

    public canExit = (direction: MovingDirection): boolean => {
        return false;
    }


    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private modal: ModalDialogService,
        private viewRef: ViewContainerRef,
        private apiGateway: ApiGatewayService,
        private orderData: OrderDataService,
        private personAccountService: PersonAccountDataService,
        private leadData: LeadDataService,
        public productData: ProductDataService,
        private notificationService: NotificationService,
        public envSvc: EnvironmentService) {
    }


    get personInformation(): string {
        if (this.orderData.order.isAccountManaged()) {
            return this.personInformationAccountManaged;
        }
        return this._personInformation;
    }

    set personInformation(value: string) {
        this._personInformation = value;
    }

    ngOnInit() {
        this.checkBrowserSupport();

        this.displayPartnerLogo();

        this.notificationService.notification$.subscribe(message => {
            if (message) {
                this.connectionError = true;
                this.showErrorModal(message.title, message.text);
            }
        });

        this.activatedRoute.queryParamMap.subscribe(params => {

            this.queryParams = { ...params.keys, ...params } as QueryParamObject;


            const personAccountId = this.queryParams.params.pid;
            const leadId = this.queryParams.params.lid;

            if (personAccountId) {
                this.personAccountService.getPersonAccount(personAccountId).then((result) => {
                    this.orderData.order.SelfAccountId = personAccountId;
                    this.orderData.order.SelfContactId = "";

                });
            }

            if (leadId) {
                this.leadData.getLead(leadId).then((result) => {
                    this.orderData.order.LeadId = leadId;

                });
            }
        });
    }

    onStepEnter(stepComponent) {
        this.canExit = (direction: MovingDirection): boolean => {
            return false;
        };

        if (!stepComponent) {
            return;
        }

        if (stepComponent.hideNavBar) {
            this.navBarLocation = '';
        } else {
            this.navBarLocation = 'top';
        }

        this.canExit = (direction: MovingDirection): boolean => {
            if (direction === MovingDirection.Backwards || !stepComponent.formFields) {
                return true;
            }

            let canExit = stepComponent.formFields.reduce(
                function(prev, cur, idx, array) { return prev && cur.ngModel.valid},
                true);
            if(stepComponent.AddressFields) {
                canExit = canExit && stepComponent.AddressFields.reduce(
                    function(prev, cur, idx, array) { return prev && cur.isValid()},
                    true);
            }

            if (!canExit) {
                stepComponent.formFields.forEach((formField) => {
                    formField.ngModel.control.markAsTouched();
                });
                if(stepComponent.AddressFields) {
                    stepComponent.AddressFields.forEach((AddressField) => {
                        AddressField.markAsTouched();
                    });
                }
                const text = 'Please fill out or correct the form fields indicated in red.';
                this.showErrorModal('Form Errors', text);
            }

            return canExit && !this.connectionError;
        };

        if (stepComponent.onStepEnter) {
            stepComponent.onStepEnter();
        }

        // Scroll to top of page after entering wizard step
        window.scrollTo(0, 0);
    }

    onStepExit(stepComponent) {
        if (stepComponent.onStepExit) {
            stepComponent.onStepExit();
        }
    }

    onClear(formFields) {
        if (formFields) {
            formFields.forEach((formField) => {
                if (formField.ngModel.value) {
                    formField.ngModel.control.setValue('');
                }
            });
        }
    }

    onSendOrder() {
        if (this.canExit(MovingDirection.Forwards)) {
            const hasPremiumCreditRepairPricing: boolean = !!this.productData.premiumCreditRepairFamilies;
            let productFamily;
            let productName;
            if (hasPremiumCreditRepairPricing) {
                productFamily = this.productData.findFamilyTypeFamily(this.productData.premiumCreditRepairFamilies, ProductFamilyType.Self).FamilyName;
                productName = this.productData.findFamilyTypeFamily(this.productData.premiumCreditRepairFamilies, ProductFamilyType.Self).Self.ProductName;
            } else {
                productFamily = this.envSvc.getProductFamilyFromSubdomain();
                productName = this.productData.productFamilyName;
            }

            this.apiGateway.postOrder(this.orderData.order, productFamily, productName).then((orderSendResponse) => {
                const executionUuid = orderSendResponse.uuid;
                this.checkOrderStatus(executionUuid);
            }).catch((err) => {
                console.error(err);
                throw err;
            });
        }
    }

    /**
     * Checks the status of the step function given an execution ID
     * @param executionUuid
     */
    checkOrderStatus(executionUuid: string) {
        this.apiGateway.getOrderStatus(executionUuid).then((orderStatusResponse) => {
            this.handleOrderStatus(executionUuid, orderStatusResponse);
        }).catch((err) => {
            this.notificationService.notify(err);
            console.error(err);
            throw err;
        });
    }

    /**
     * Handles the result of the call to check order status.
     * Retries check if SFN is still running, sends paylink url if succeeded, and errors if other status.
     * @param executionUuid
     * @param orderStatusResponse
     */
    handleOrderStatus(executionUuid: string, orderStatusResponse: any) {
        const retryTimeout = 3000;
        const status = orderStatusResponse.status;

        switch (status) {
            case this.RUNNING:
                setTimeout(() => {
                    this.checkOrderStatus(executionUuid);
                }, retryTimeout);
                break;
            case this.SUCCEEDED:
                this.notificationService.notifyPaylinkRetrieved(orderStatusResponse.url);
                break;
            default:
                const err = new Error(`Order failed. Order execution status: ${status}`);
                this.notificationService.notify(err);
                console.error(err);
                throw err;
        }
    }

    // https://stackoverflow.com/a/5918791/10902491
    browserDetection() {
        const edge = navigator.userAgent.indexOf('Edge/'); // only Edge UA contains edge (along with other browsers in the UA string for edge)
        if (edge > 0) {
            // Edge (IE 12+) => return version number
            const version = parseInt(navigator.userAgent.substring(edge + 5, navigator.userAgent.indexOf('.', edge)), 10);
            return ['Edge', version];
        }

        let ua = navigator.userAgent, tem,
            M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

        if (/trident/i.test(M[1])) { // IE 11
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return ['msie', (tem[1] || '')];
        }
        if (M[1] === 'Chrome') {
            tem = ua.match(/\b(OPR)\/(\d+)/);
            if (tem != null) {
                return tem.slice(1).join(' ').replace('OPR', 'Opera');
            }
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) {

            M.splice(1, 1, tem[1]);
        }

        return M;
    }

    checkBrowserSupport() { // todo: json lang file add
        if (this.isAllowedBrowser() === false) {
            const browserDetails = this.browserDetection();
            const title = 'Unsupported Browser';
            const message = `
                <p>Your browser (${browserDetails[0]} ${browserDetails[1]}) is unsupported. We support the following browsers:</p>
                <p>
                    <strong>Microsoft®</strong></br>
                    Windows® 7 or higher</br>
                    Internet Explorer 11.x</br>
                    Microsoft Edge 40+</br>
                </p>
                <p>
                    <strong>Google® ChromeTM</strong></br>
                    Windows® 10 or higher and Mac OS X 10.9+</br>
                    Version 50+</br>
                </p>
                <p>
                    <strong>Apple® SafariTM</strong></br>
                    Mac OS X 10.9+</br>
                    Version 9+</br>
                </p>
                <p>
                    <strong>Mozilla Firefox</strong></br>
                    Windows® 10 or higher and Mac OS X 10.9+</br>
                    Version 50+</br>
                </p>
            `;
            this.showErrorModal(title, message, true);
        }
    }

    isAllowedBrowser(): boolean { // todo: dont hardcode browsers and allowed versions
        const browserAndVersion: string[] = this.browserDetection();

        const browser: string = browserAndVersion[0];
        const version: string = browserAndVersion[1];
        let versionNumber: number;
        let allowedVersion: number;
        try {
            versionNumber = Number(version);
        } catch (err) {
            console.error(err);
        }

        switch (browser.toLowerCase()) {
            case 'chrome':
                allowedVersion = 50;
                break;
            case 'firefox':
                allowedVersion = 50;
                break;
            case 'msie':
                allowedVersion = 11;
                break;
            case 'edge':
                allowedVersion = 15.15; // first edge 40+ version (40.15063) is EdgeHTML 15.15063
                break;
            case 'safari':
                allowedVersion = 9;
                break;
            default:
                return false;
        }
        return this.isAllowedVersion(versionNumber, allowedVersion);
    }

    isAllowedVersion(current: number, allowed: number) {
        return current >= allowed;
    }

    private showErrorModal(title: string, text: string, removeDismissButtons?: boolean) {
        const actionButtons = removeDismissButtons ? null : [{ text: 'OK', buttonClass: 'btn btn-light' }];
        const options = {
            childComponent: SimpleModalComponent,
            settings: {
                contentClass: 'modal-content error'
            },
            title: title,
            data: {
                text: text
            },
            actionButtons: actionButtons
        };
        this.modal.openDialog(this.viewRef, options);
    }

    private displayPartnerLogo() {
        this.activatedRoute.params.subscribe((routeParams) => {
            if (routeParams.partnerKey) {
                this.partnerKey = routeParams.partnerKey;
                if (this.partnerKey in this.envSvc.config.displayPartnerLogo){
                    this.logoPath = this.envSvc.config.displayPartnerLogo[this.partnerKey];
                    this.logoStyleType = 'panel';
                }
            }
        });
    }

    isCyberSweep() {
        let cyberSweepResult = false;
        if (this.envSvc.getProductFromSubdomain() === this.CYBERSWEEP) {
            cyberSweepResult = true;
        }
        return cyberSweepResult;
    }
}

export interface QueryParamObject {
    '0'?: string;
    '1'?: string;
    'params'?: {
        'lid'?: string;
        'pid'?: string;
        'partnerKey'?: string;
    };
}
