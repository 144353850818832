import {Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {AddressModel} from "../../models/address-model";
import { FormsModule } from '@angular/forms';
import {FormFieldComponent} from "../form-field/form-field";

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {


  @ViewChildren(FormFieldComponent) formFields: QueryList<FormFieldComponent>;
  readonly stateOptions = AddressModel.StateOptions;
  @Input() data: AddressModel = new AddressModel();
  @Input() required: boolean = false;
  @Output() dataChange = new EventEmitter();
  private _city:string;
  private _street:string;
  private _state:string;
  private _zip:string;

  constructor() {
  }

  ngOnInit() {
  }

  get Address(): AddressModel {
    return this.data;
  }

   isValid(): boolean{
    /* this.formFields.forEach((formField) => {
       valid = valid && formField.ngModel.valid;
     });*/
   return this.formFields.reduce(
       function(prev, cur, idx, array) {
         return prev && cur.ngModel.valid
       },
         true);
  }

  markAsTouched(){
    this.formFields.forEach((formField) => {
      formField.ngModel.control.markAsTouched();
    });
  }

  set Address(value: AddressModel) {
    this.data = value;
    this.dataChange.emit(this.data);
  }
  
  get City(): string {
    return this._city;
  }

  set City(value:string){
    this._city = value;
    this.data.City = value;
    this.dataChange.emit(this.data);
  }

  get State(): string {
    return this._state;
  }

  set State(value:string){
    this._state = value;
    this.data.State = value;
    this.dataChange.emit(this.data);
  }

  get Street(): string {
    return this._street;
  }

  set Street(value:string){
    this._street = value;
    this.data.Street = value;
    this.dataChange.emit(this.data);
  }

  get Zip(): string {
    return this._zip;
  }

  set Zip(value:string){
    this._zip = value;
    this.data.Zip = value;
    this.dataChange.emit(this.data);
  }

}
